import { useEffect } from 'react'
import { ILessonPlan } from '../../../../../../interfaces/ILessonPlan'
import {LessonPlanForm} from '../../../../../../components/LessonPlanForm/Index';
import {useStudentPageContext} from '../../../../StudentsPageContext';

export function LessonPlanCard(props: { lessonPlan: ILessonPlan }) {
    const {studentSelected} = useStudentPageContext();


    return (
        <LessonPlanForm student={studentSelected!} backToList={()=>{}} prevLessonPlanData={props.lessonPlan}/>
    )
}
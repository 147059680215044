import { useState } from 'react';
import { useHomeContext } from '../contexts/HomeContext';

export function useHandleApiRequest<T>() {
    const { catchErrorHandled } = useHomeContext();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [responseData, setResponseData] = useState<T | undefined>(); // Defina o tipo de responseData conforme necessário

    async function handleRequest(request: () => Promise<any>, msgOfSuccess?: string) {
        setIsLoading(true);
        try {
            const data = await request();
            setResponseData(data); // Define o valor do dado retornado pela requisição
            if (msgOfSuccess) {
                alert(msgOfSuccess);
            }
        } catch (err) {
            console.log('err: ', err);
            throw catchErrorHandled(err);
        } finally {
            setIsLoading(false);
        }
    }

    return { isLoading, responseData, handleRequest };
}

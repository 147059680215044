import React from 'react';

import './styles.scss';

interface IModalFade {
    children: JSX.Element;
    isShowingModal: boolean;
    setIsShowingModal?: (value: boolean) => void;
}

export function ModalFade(props: IModalFade) {
    const { children, isShowingModal } = props;

    return (
        <>
            {isShowingModal ? (
                <div className="modal fade generic-modal-fade active show" tabIndex={-1}
                    style={{ display: "block" }} aria-modal="true" role="dialog">
                    {children}
                </div>
            ) : (<></>)}
        </>
    )
}
import React, { useEffect, useState } from 'react';
import { useAuthContext } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { GoogleSign } from './GoogleSign/Index';
import { getEmailFromGoogleCredential, validateEmailAndGetUserWithToken } from '../../services/auth';
import { AuthError } from '../../exceptions/AuthError';
import { setTokensToLocalStorage } from '../../utils/localStorage';


import './styles.scss';
import { useUserAuthentication } from '../../hooks/useUserAuthentication';
import { SpinnerOverModal } from '../../components/Spinner/Index';

export function Login() {
    const { isLoadingUser } = useUserAuthentication();
    const navigate = useNavigate();
    const { setUserLogged, tokenClient } = useAuthContext();

    async function authSignIn({ googleCredential, driveAccessToken }: { googleCredential: string, driveAccessToken: string }) {

        try {
            const { email } = await getEmailFromGoogleCredential(googleCredential);

            const { specialEdToken, user } = await validateEmailAndGetUserWithToken(email);


            setTokensToLocalStorage({
                driveAccessToken, specialEdToken
            });

            setUserLogged({
                user
            });
            return navigate('/');

        } catch (err) {
            setUserLogged(null);
            if (err instanceof AuthError) {
                alert(err.message);
            } else {
                alert("User authentication unavailable");
            }
        }
    }


    return (
        <>
            {isLoadingUser ? (
                <div>
                    <SpinnerOverModal />
                </div>
            ) : (
                <div className='login-page'>
                    <div className="login-card d-flex flex-column justify-content-between align-items-center bg-white text-center rounded p-2">

                        <div className="ear-logo"></div>

                        <div className='d-flex flex-column justify-content-end login-content'>
                            <div className='mb-2'>
                                <h2 className='title mt-0 mb-0'>LSA</h2>

                                <span className="text-secondary mt-2">
                                    Learning Support App
                                </span>
                            </div>

                            <div className='my-2 text-center google-btn mx-auto'>
                                <GoogleSign authLogin={authSignIn} tokenClient={tokenClient} />
                            </div>
                        </div>


                    </div>
                </div>
            )}
        </>

    )
}
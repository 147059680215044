import { useState, useEffect } from 'react';
import { useAuthContext } from '../contexts/AuthContext';
import { getUserByToken } from '../services/auth';
import { useNavigate } from 'react-router-dom';


export function useUserAuthentication() {
    const [isLoadingUser, setIsLoadingUser] = useState(true);
    const navigate = useNavigate();
    const { setUserLogged } = useAuthContext();

    async function handleLoadUser() {
        try {
            const { user } = await getUserByToken();

            setUserLogged({
                user: user,
            })

            return navigate('/');
        } catch (err) {
            setUserLogged(null);
            localStorage.clear();
            return navigate('/login');
        } finally {
            setIsLoadingUser(false);
        }
    }

    useEffect(() => {
        handleLoadUser();
    }, []);

    return { isLoadingUser };
}
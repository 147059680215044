import React, { useEffect, useRef, useState } from 'react';
import { IFileFullInfo } from '../../../../../interfaces/IFile';
import { SpinnerOverModal } from '../../../../../components/Spinner/Index';

import './styles.scss';

import eagle from '../../../../../assets/eagle.svg';

interface IFileViewFullScreen {
    fileInView: IFileFullInfo;
    closeModal: () => void;
}

export function FileViewFullScreen(props: IFileViewFullScreen) {
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const [isLoadingIFrame, setIsLoadingIFrame] = useState<boolean>(true);
    const [isInEdition, setIsInEdition] = useState<boolean>(false);

    useEffect(() => {
        setIsLoadingIFrame(true);
    }, [isInEdition]);


    useEffect(() => {
        const handleLoad = () => {
            setIsLoadingIFrame(false);
        };

        const iframe = iframeRef.current;
        if (iframe) {
            iframe.addEventListener('load', handleLoad);
        }

        return () => {
            if (iframe) {
                iframe.removeEventListener('load', handleLoad);
            }
        };
    }, []);

    return (
        <div className='modal-container-background drag-area' id="file-full-screen">



            <div className='file-full-screen-header'>

                <button type="button" className="btn btn-outline-primary btn-sm" onClick={props.closeModal}>Exit full screen</button>

                <div>
                    <h4>LSA</h4>
                    <img src={eagle} alt='eagle' />
                </div>

                <div className="form-check form-switch me-4 toggle-btn">
                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault"
                        defaultChecked={isInEdition} onChange={(e) => { setIsInEdition(e.target.checked) }} />
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault" >Edition mode</label>
                </div>

            </div>
            {
                isLoadingIFrame ? (
                    <SpinnerOverModal />
                ) : (
                    <></>
                )
            }
            <iframe
                title="drive-full-screen-doc"
                src={isInEdition ? `https://docs.google.com/document/d/${props.fileInView.id}/edit` : `${props.fileInView.embedUrl}`}
                style={{ width: "100%", height: "100vh", border: "1px solid #dfdfdf" }}
                ref={iframeRef}
            ></iframe>
        </div>
    );
}

const localStorageItemNames = {
    specialEdToken: 'specialEdToken',
    driveAccessToken: 'driveAccessToken',
}

export function setTokensToLocalStorage({ specialEdToken, driveAccessToken }: { specialEdToken: string, driveAccessToken: string }) {
    localStorage.setItem(localStorageItemNames.specialEdToken, specialEdToken);
    localStorage.setItem(localStorageItemNames.driveAccessToken, driveAccessToken);
}

export function getTokenFromLocalStorage() {
    const driveAccessToken = localStorage.getItem(localStorageItemNames.driveAccessToken);
    const specialEdToken = localStorage.getItem(localStorageItemNames.specialEdToken);

    return {
        driveAccessToken,
        specialEdToken
    }
}
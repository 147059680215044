import { AddStudent } from "../AddStudent/Index";
import { StudentProfile } from "../StudentProfile/Index";
import { StudentResearch } from "../StudentResearch/Index";
import { StudentsList } from "../StudentsList/Index";
import { useStudentPageContext } from "../StudentsPageContext";

export function StudentPageContent() {
    const { studentSelected } = useStudentPageContext();

    return (
        <div className='row'>
            <div className="col">
                {studentSelected ? (
                    <StudentProfile studentSelected={studentSelected} />
                ) : (
                    <div className='row'>
                        <div className="col-10 mb-4">
                            <div className="row justify-content-between align-items-end">
                                <div className="col-9">
                                    <StudentResearch />
                                </div>

                                <div className="col-3">
                                    <AddStudent />
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <StudentsList />
                        </div>

                    </div>
                )}
            </div>
        </div>
    )
} 
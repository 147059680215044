import React, { useEffect } from 'react';
import { StudentDocuments } from './StudentDocuments/Index';
import { getStudentByDocId } from '../../../services/students';

import { SpinnerOverModal } from '../../../components/Spinner/Index';
import { removeStudentFromCookie } from '../../../utils/cookiesMngt';
import { useStudentPageContext } from '../StudentsPageContext';

import './styles.scss';
import { IStudentBasicInfo, IStudentFullInfo } from '../../../interfaces/IStudent';
import { useHandleApiRequest } from '../../../hooks/useHandleApiRequest';

import { StudentInfo } from './StudentInfo/index';

import { LessonPlanArea } from './LessonPlanArea/Index';

interface IStudentProfile {
    studentSelected: IStudentBasicInfo
}

export function StudentProfile(props: IStudentProfile) {
    const { setStudentSelected } = useStudentPageContext();

    const { handleRequest, isLoading, responseData: studentInProfile } = useHandleApiRequest<IStudentFullInfo>();

    useEffect(() => {
        handleGetStudentByDoc();
    }, []);

    function handleGetStudentByDoc() {
        handleRequest(() => getStudentByDocId({ docId: props.studentSelected.docId })).catch(() => {
            setStudentSelected(undefined);
            removeStudentFromCookie(props.studentSelected.docId);
        });
    }

    return (
        <div className="row">
            {
                isLoading ? (
                    <SpinnerOverModal />
                ) : (
                    <></>
                )
            }

            {
                studentInProfile ? (
                    <div className='d-flex gap-5'>

                        <StudentInfo studentInProfile={studentInProfile} refreshData={handleGetStudentByDoc} />
                        <div className='d-flex flex-column'>
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="documentation-tab" data-bs-toggle="tab" data-bs-target="#documentation" type="button" role="tab" aria-controls="documentation" aria-selected="true">Documents</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="lesson-plan-tab" data-bs-toggle="tab" data-bs-target="#lesson-plan" type="button" role="tab" aria-controls="lesson-plan" aria-selected="true">Lesson Plan</button>
                                </li>
                            </ul>
                            <div className="tab-content profile-tabs" id="myTabContent">
                                <div className="tab-pane profile-tab fade show active" id="documentation" role="tabpanel" aria-labelledby="documentation-tab">
                                    <StudentDocuments studentInProfile={studentInProfile} />
                                </div>
                                <div className="tab-pane profile-tab " id="lesson-plan" role="tabpanel" aria-labelledby="lesson-plan-tab">
                                    <LessonPlanArea student={studentInProfile}/>
                                </div>
                            </div>
                        </div>

                    </div>
                ) : (<></>)
            }
        </div>
    )
}
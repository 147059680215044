import React, { useState } from "react";

import { useStudentFormContext } from "../../../../../contexts/StudentFormContext";

interface IDiagnosisInput {
  diagnosesList: string[];
  setDiagnosesList: (value: string[]) => void;
  isShowingMore: boolean;
  setIsShowingMore: (value: boolean) => void;
}

function DiagnosisInput({
  diagnosesList,
  setDiagnosesList,
  isShowingMore,
  setIsShowingMore,
}: IDiagnosisInput) {
  const { setValue } = useStudentFormContext();

  function getDiagnosesByIsShowingMore() {
    if (isShowingMore) {
      return diagnosesList;
    } else {
      return diagnosesList.filter((diagnosis, index) => index < 2);
    }
  }

  function getDiagnosis(diagnosis: string) {
    return diagnosis && diagnosis.trim().length > 0 ? diagnosis : "undefined";
  }

  function handleRemoveDiagnosis(diagnosis: string) {
    const newDiagnoses = diagnosesList.filter(
      (diagInArray) =>
        diagInArray.trim().toUpperCase() !== diagnosis.trim().toUpperCase()
    );
    setDiagnosesList(newDiagnoses);
    setValue("diagnoses", newDiagnoses);
  }

  return (
    <div className="d-flex flex-wrap align-items-center special-info mb-2 mx-0">
      {getDiagnosesByIsShowingMore().length > 0 ? (
        <>
          {getDiagnosesByIsShowingMore().map((diagnosis, index) => {
            return (
              <span
                className="my-1 me-1 badge bg-secondary"
                key={index}
                onClick={() => {
                  handleRemoveDiagnosis(diagnosis);
                }}
              >
                {getDiagnosis(diagnosis)}
                <span
                  style={{ cursor: "pointer" }}
                  className="ms-2 badge bg-white text-dark"
                  key={index}
                >
                  X
                </span>
              </span>
            );
          })}

          {diagnosesList.length > 2 ? (
            <a
              data-bs-toggle="collapse"
              role="button"
              onClick={() => {
                setIsShowingMore(!isShowingMore);
              }}
              href="#"
            >
              {isShowingMore ? "see less." : "see more..."}
            </a>
          ) : (
            <></>
          )}
        </>
      ) : (
        <span className="text-secondary">Has not been diagnosed.</span>
      )}
    </div>
  );
}

export function DiagnosesSelection({ diagnoses }: { diagnoses: string[] }) {
  const DIAGNOSES_INPUT = "diagnosesInput";
  const [diagnosesList, setDiagnosesList] = useState<string[]>(diagnoses);
  const [isShowingMore, setIsShowingMore] = useState(false);
  const { setValue } = useStudentFormContext();

  function handleSetDiagnosesList() {
    const diagnosisInputEl = document.getElementById(
      DIAGNOSES_INPUT
    ) as HTMLInputElement;

    if (diagnosisInputEl instanceof HTMLInputElement) {
      const newDiagnosis = diagnosisInputEl.value;
      const newDiagnosisAlreadyExist =
        diagnosesList.filter(
          (diagnosis) =>
            diagnosis.trim().toUpperCase() == newDiagnosis.trim().toUpperCase()
        ).length > 0;

      if (!newDiagnosisAlreadyExist && newDiagnosis.trim() !== "") {
        setDiagnosesList((oldArray) => {
          setValue("diagnoses", [...oldArray, newDiagnosis])
          return [...oldArray, newDiagnosis]
        });

        if (!isShowingMore) {
          setIsShowingMore(true);
        }
      }

      diagnosisInputEl.value = "";
    } else {
      alert("Was not possible to add new Diagnosis");
    }
  }

  return (
    <div className="mb-3">
      <label htmlFor={DIAGNOSES_INPUT} className="form-label ">
        Diagnosis
      </label>

      <div className="input-group input-group-sm mb-1">
        <input
          id={DIAGNOSES_INPUT}
          type="text"
          className="form-control"
          placeholder="Ex: ADHD"
          aria-label="Recipient's username"
          aria-describedby="button-addon2"
        />

        <button
          className="btn btn-primary"
          type="button"
          id="button-addon2"
          onClick={handleSetDiagnosesList}
        >
          Add
        </button>
      </div>

      <DiagnosisInput
        isShowingMore={isShowingMore}
        setIsShowingMore={setIsShowingMore}
        diagnosesList={diagnosesList}
        setDiagnosesList={setDiagnosesList}
      />
    </div>
  );
}

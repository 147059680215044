import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ModalFade } from '../../../components/ModalFade/Index';
import grades from '../../../data/grades.json';
import teachers from '../../../data/teachers.json';
import { addStudent } from '../../../services/students';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useHandleApiRequest } from '../../../hooks/useHandleApiRequest';
import { SpinnerOverModal } from '../../../components/Spinner/Index';
import { useStudentPageContext } from '../StudentsPageContext';
import { IStudentBasicInfo } from '../../../interfaces/IStudent';

export function AddStudent() {
    const [isShowingStudentForm, setIsShowingStudentForm] = useState<boolean>(false);


    const { userHasPermission } = useAuthContext();
    return (
        <div className="row justify-items-end">
            {
                userHasPermission('E1') ? (
                    <div className="col">
                        <button className='btn btn-primary  btn-primary-sm' disabled={isShowingStudentForm} onClick={() => { setIsShowingStudentForm(true) }}>
                            Add Student
                        </button>
                        <StudentFormModal

                            isShowingStudentForm={isShowingStudentForm}
                            setIsShowingStudentForm={setIsShowingStudentForm}
                        />
                    </div>
                ) : (<></>)
            }

        </div>

    )
}

interface IStudentFormModalProps {
    isShowingStudentForm: boolean;
    setIsShowingStudentForm: (value: boolean) => void
}

function StudentFormModal(props: IStudentFormModalProps) {
    const { register, handleSubmit, formState: { errors }, reset } = useForm<IStudentBasicInfo>();
    const { isLoading, handleRequest, responseData } = useHandleApiRequest<IStudentBasicInfo>();
    const { setStudentSelected } = useStudentPageContext();

    useEffect(() => {
        if (responseData)
            setStudentSelected(responseData);
    }, [responseData]);

    async function onSubmit(student: IStudentBasicInfo) {
        handleRequest(() => addStudent(student), "Student successfully inserted").then(() => { resetAndCloseForm() });
    }

    function resetAndCloseForm() {
        reset();
        props.setIsShowingStudentForm(false)
    }


    return (
        <>
            {
                isLoading && <SpinnerOverModal />
            }
            <ModalFade isShowingModal={props.isShowingStudentForm}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add New Student</h5>
                            <button type="button" className="btn-close" aria-label="Close" onClick={resetAndCloseForm}></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row mb-3">
                                    <div className="col-3 has-validation">
                                        <label htmlFor="validationFamilyId" className="form-label">ID</label>
                                        <input type="text" className={`form-control form-control-sm  ${errors.studentId ? ("is-invalid") : ("")}`} id="studentId"
                                            {...register("studentId", {
                                                validate: (value: string) => {
                                                    if (!value) {
                                                        return "Invalid family id format.";
                                                    }

                                                    const regex = /^[0-9]+\/[0-9]+$/;
                                                    if (!regex.test(value.trim())) {
                                                        return "Invalid family id format.";
                                                    }
                                                    return undefined;
                                                }
                                            })}
                                        />
                                        {errors.studentId && (
                                            <span className="invalid-feedback">{errors.studentId.message}</span>
                                        )}
                                    </div>
                                    <div className="col">
                                        <label htmlFor="name" className="form-label">Full Name</label>
                                        <input type="text" className={`form-control form-control-sm ${errors.name ? ("is-invalid") : ("")}`}
                                            id="name"
                                            {...register("name", {
                                                validate: (value: string) => {
                                                    if (!value) {
                                                        return "Invalid first name.";
                                                    }

                                                    if (value.trim().length < 2) {
                                                        return "Invalid first name.";
                                                    }

                                                    return undefined;
                                                }
                                            })}
                                        />
                                        {errors.name && (
                                            <span className="invalid-feedback">{errors.name.message}</span>
                                        )}
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col">
                                        <label htmlFor="gradeLevel" className="form-label">Grade Level</label>
                                        <select className={`form-select form-select-sm ${errors.gradeLevel ? ("is-invalid") : ("")}`} id="gradeLevel"
                                            aria-label=".form-select-sm"
                                            {...register("gradeLevel.docId", {
                                                validate: (value: string) => {
                                                    if (!value || value.trim() === "") {
                                                        return "Invalid grade level.";
                                                    }

                                                    return undefined;
                                                }
                                            })}>
                                            <option selected value="">Select a level...</option>
                                            {
                                                grades.gradeLevels.map((gradeLevel, index) => {
                                                    return (
                                                        <option value={gradeLevel.docId} key={index}>{gradeLevel.level}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        {errors.gradeLevel && (
                                            <span className="invalid-feedback">{errors.gradeLevel.message}</span>
                                        )}
                                    </div>
                                    <div className="col">
                                        <label htmlFor="gradeDivision" className="form-label">Grade Division</label>
                                        <select className={`form-select form-select-sm  ${errors.gradeDivision ? ("is-invalid") : ("")}`} id="gradeDivision"
                                            {...register("gradeDivision.docId")}>
                                            <option selected value="">Select a division...</option>
                                            {
                                                grades.gradeDivisions.map((gradeDivision, index) => {
                                                    return (
                                                        <option value={gradeDivision.docId} key={index}>{gradeDivision.division}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        {errors.gradeDivision && (
                                            <span className="invalid-feedback">{errors.gradeDivision.message}</span>
                                        )}
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <button type="button" className="btn btn-secondary me-2" onClick={resetAndCloseForm}>Cancel</button>
                                    <button type="submit" className="btn btn-primary">Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </ModalFade>
        </>


    )
}
import { IUser, IUserToSaveInApi } from '../interfaces/IUser';
import { httpStatus } from '../utils/httpStatus';
import { ApiException } from '../interfaces/ApiException';
import { getTokenFromLocalStorage } from '../utils/localStorage';


export async function getUsersPerPage({ email }: { email: string }) {
    const fetchResponse = await fetch(`${process.env.REACT_APP_API_CURRENT_URL}/users?email=${email}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${getTokenFromLocalStorage().specialEdToken}`,
            'Content-Type': 'application/json'
        }
    });

    const responseJson = await fetchResponse.json();

    if (responseJson.status !== httpStatus.success) {

        const exception: ApiException = {
            lsaMessage: responseJson.lsaMessage,
            status: responseJson.status,
            hasToSignOut: responseJson.status === httpStatus.Unauthorized
        }
        throw exception;
    }

    return responseJson.usersFound as IUser[];

}

export async function addUser({ user }: { user: IUserToSaveInApi }) {

    const fetchResponse = await fetch(`${process.env.REACT_APP_API_CURRENT_URL}/users`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${getTokenFromLocalStorage().specialEdToken}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "user": user
        })
    });

    const responseJson = await fetchResponse.json();

    if (responseJson.status !== httpStatus.success) {
        const exception: ApiException = {
            lsaMessage: responseJson.lsaMessage,
            status: responseJson.status,
            hasToSignOut: responseJson.status === httpStatus.Unauthorized
        }
        throw exception;
    }

    const userAdded = responseJson.userAdded as IUser;

    if (userAdded) {
        return user;
    } else {
        throw new Error("Was not possible to add the user")
    }

}

export async function updateUser({ docId, user }: { docId: string, user: IUser }) {
    const fetchResponse = await fetch(`${process.env.REACT_APP_API_CURRENT_URL}/users/${docId}`, {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${getTokenFromLocalStorage().specialEdToken}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "user": user
        })
    });

    const responseJson = await fetchResponse.json();

    if (responseJson.status !== httpStatus.success) {
        const exception: ApiException = {
            lsaMessage: responseJson.lsaMessage,
            status: responseJson.status,
            hasToSignOut: responseJson.status === httpStatus.Unauthorized
        }
        throw exception;
    }

    if (typeof responseJson.userUpdated === 'object' && (!Array.isArray(responseJson.userUpdated))) {
        const userUpdated = responseJson.userUpdated as IUser;


        return {
            userUpdated: userUpdated
        }
    }

    return { studentUpdated: undefined };
}
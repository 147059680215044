import React from 'react';
import './styles.scss';

export function LoadingAnimation() {
    return (
        <ul className="loading-animation">
            <li>
                <span className="dot"></span>
                <span className="dot"></span>
                <span className="dot"></span>
            </li>
        </ul>
    );
}
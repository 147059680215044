import { IStudentBasicInfo } from '../interfaces/IStudent';

export function addStudentToCookie(student: IStudentBasicInfo) {
    const cookies = getCookie('students');
    console.log('cookies: ', cookies);
    const studentsList = cookies.length > 0 ? (JSON.parse(getCookie('students')) as IStudentBasicInfo[]) : ([]);
    if (studentsList.filter((studentCookie) => studentCookie.docId === student.docId).length <= 0) {
        studentsList.push(student);
        setCookie('students', JSON.stringify(studentsList), 7);
    }
}

export function setCookie(name: string, value: string, expDays: number) {
    const d = new Date();
    d.setTime(d.getTime() + (expDays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + d.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
}


export function getCookie(name: string) {
    const cname = name + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(cname) === 0) {
            return c.substring(cname.length, c.length);
        }
    }
    return "";
}

export function removeStudentFromCookie(docId: string) {
    const cookies = getCookie('students');
    const studentsList = cookies.length > 0 ? JSON.parse(getCookie('students')) as IStudentBasicInfo[] : [];

    // Encontra o índice do estudante com o docId fornecido na lista de estudantes
    const index = studentsList.findIndex(student => student.docId === docId);

    if (index !== -1) {
        // Remove o estudante da lista
        studentsList.splice(index, 1);

        // Atualiza os cookies com a lista atualizada de estudantes
        setCookie('students', JSON.stringify(studentsList), 7);
    }
}

export function clearStudentsFromCookie() {
    // Remove o cookie 'students' definindo-o como uma string vazia e com data de expiração no passado
    setCookie('students', '', -1);
}
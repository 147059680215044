import { useState } from 'react';

export function DiagnosisPresentation({ diagnoses }: { diagnoses: string[] }) {
    const [isShowingMore, setIsShowingMore] = useState(false);

    function getDiagnosis(diagnosis: string) {
        return diagnosis && diagnosis.trim().length > 0 ? diagnosis : "-";
    }

    return (
        <>
            <span className="me-2">Diagnosis:</span>

            <div>
                {diagnoses.length > 0 ? (
                    <>
                        {diagnoses.map((diagnosis, index) => {
                            if (index < 2) {
                                return (
                                    <span className="m-1 badge bg-secondary text-wrap" key={index}>
                                        {getDiagnosis(diagnosis)}
                                    </span>
                                );
                            }
                        })}

                        {diagnoses.length >= 3 ? (
                            <>
                                {!isShowingMore ? (
                                    <a
                                        data-bs-toggle="collapse"
                                        href="#diagnosisCollapse"
                                        role="button"
                                        aria-expanded="false"
                                        aria-controls="diagnosisCollapse"
                                        onClick={() => {
                                            setIsShowingMore(true);
                                        }}
                                    >
                                        More..
                                    </a>
                                ) : (
                                    <></>
                                )}

                                <div className="collapse" id="diagnosisCollapse">
                                    {diagnoses.map((diagnosis, index) => {
                                        if (index >= 2) {
                                            return (
                                                <span className="m-1 badge bg-secondary " key={index}>
                                                    {getDiagnosis(diagnosis)}
                                                </span>
                                            );
                                        }
                                    })}
                                </div>
                                {isShowingMore ? (
                                    <a
                                        data-bs-toggle="collapse"
                                        href="#diagnosisCollapse"
                                        role="button"
                                        aria-expanded="false"
                                        aria-controls="diagnosisCollapse"
                                        onClick={() => {
                                            setIsShowingMore(false);
                                        }}
                                    >
                                        less.
                                    </a>
                                ) : (
                                    <></>
                                )}
                            </>
                        ) : (
                            <></>
                        )}
                    </>
                ) : (
                    <span className="text-secondary">Has not been diagnosed.</span>
                )}
            </div>
        </>
    );
}
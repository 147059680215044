import React, { ReactNode } from "react";

import {
  FieldErrors,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormReset,
  UseFormSetValue,
  UseFormUnregister,
  useForm,
} from "react-hook-form";
import { IStudentFullInfo } from "../interfaces/IStudent";

interface IStudentFormContext {
  register: UseFormRegister<IStudentFullInfo>;
  handleSubmit: UseFormHandleSubmit<IStudentFullInfo, undefined>;
  reset: UseFormReset<IStudentFullInfo>;
  formState: {
    errors: FieldErrors<IStudentFullInfo>;
  };
  setValue: UseFormSetValue<IStudentFullInfo>;
  unregister: UseFormUnregister<IStudentFullInfo>
}

const StudentFormContext = React.createContext<IStudentFormContext | undefined>(
  void 0
);

interface IProviderProps {
  children: ReactNode;
}

export const StudentFormContextProvider = (props: IProviderProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    unregister
  } = useForm<IStudentFullInfo>();
  const { children } = props;

  const value = {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    unregister
  };

  return (
    <StudentFormContext.Provider value={value}>
      {children}
    </StudentFormContext.Provider>
  );
};

export function useStudentFormContext() {
  const context = React.useContext(StudentFormContext);

  if (typeof context === "undefined") {
    throw new Error("Unknown error");
  }
  return context;
}

import {ILessonPlan} from '../interfaces/ILessonPlan';
import { throwApiException } from '../utils/throwApiException';
import { getTokenFromLocalStorage } from '../utils/localStorage';
import { IStudentBasicInfo } from '../interfaces/IStudent';
import {parseISODateString, getDateFormatted} from '../utils/dateUtils';



async function addLessonPlan(lessonPlan: ILessonPlan): Promise<void> {

    const response = await fetch(`${process.env.REACT_APP_API_CURRENT_URL}/lessonPlan/add`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${getTokenFromLocalStorage().specialEdToken}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "lessonPlan": lessonPlan
        })
    });

    if (!response.ok) {
        await throwApiException(response);
    }
}


async function getLessonPlanBySearching({ student, startAfterDocId="", startDate,  endDate}: { student: IStudentBasicInfo, startAfterDocId: string, startDate: Date | undefined, endDate: Date | undefined}): Promise<ILessonPlan[]> {

    const baseUrl = `${process.env.REACT_APP_API_CURRENT_URL}/lessonPlan/searchForMany/${student.docId}?`;

    const response = await fetch(`${baseUrl}startAfterDocId=${startAfterDocId}&startDate=${startDate?(getDateFormatted(startDate)):('')}&endDate=${endDate?(getDateFormatted(endDate)):('')}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${getTokenFromLocalStorage().specialEdToken}`,
            'Content-Type': 'application/json'
        }
    });

    if (!response.ok) {
        await throwApiException(response);
    }

    const data = (await response.json()).data as ILessonPlan[];

    data.forEach(lessonPlan => {
      lessonPlan.day = parseISODateString(lessonPlan.day as unknown as string);
    });
  
    return data;
}

export { addLessonPlan, getLessonPlanBySearching }
interface IShowValueOrDefaultMessage {
    value: string | undefined,
    defaultMessage: string
}

export function  removeTimeFromDateTime(dateTime: string) {
    // Find the index of the 'T' character
    const tIndex = dateTime.indexOf('T');

    // If 'T' is found, return the substring before 'T'
    if (tIndex !== -1) {
        return dateTime.substring(0, tIndex);
    }

    // If 'T' is not found, return the original string
    return dateTime;
}

export function replaceUndinedForDash(value: unknown) {
    if (value &&
        typeof value === 'string' &&
        value.trim().toLowerCase() !== 'undefined')
        return value;
    return '-'
}

function showValueOrDefaultMessage({ value, defaultMessage }: IShowValueOrDefaultMessage) {


    if (typeof value === "string") {
        const valueTrim = value.trim();
        if (valueTrim.length > 0 && valueTrim !== 'undefined') {
            return value;
        } else {
            return defaultMessage
        }
    } else if (typeof defaultMessage === "string") {
        return defaultMessage;
    } else {
        return "";
    }
}


function isWordInArray({ word, array }: { word: string, array: string[] }) {
    return array.filter((item) => item.trim().toUpperCase() == word.trim().toUpperCase()).length > 0;
}

function areAllPropertiesEmpty(obj: any): boolean {
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            if (obj[key] !== "") {
                return false;
            }
        }
    }
    return true;
}


export { showValueOrDefaultMessage, isWordInArray, areAllPropertiesEmpty }
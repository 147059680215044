import { useStudentPageContext } from '../../StudentsPageContext';
import { StudentCard, StudentCardLoading } from '../../../../components/StudentCard/index';
import { useEffect, useRef, useState } from 'react';
import { IStudentBasicInfo } from '../../../../interfaces/IStudent';
import { useHandleApiRequest } from '../../../../hooks/useHandleApiRequest';
import { getStudentsPerPage } from '../../../../services/students';
import { areAllPropertiesEmpty } from '../../../../utils/stringUtils';

export function StudentsFromResearch() {
    const divRef = useRef<HTMLDivElement>(null);
    const { setStudentSelected, researchValues } = useStudentPageContext();
    const { isLoading, handleRequest, responseData: studentsFromRequest = [] } = useHandleApiRequest<IStudentBasicInfo[]>();
    const [studentsToShow, setStudentsToShow] = useState<IStudentBasicInfo[]>([]);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const [isAtBottom, setIsAtBottom] = useState(false);

    useEffect(() => {
        const div = divRef.current;
        if (div !== null) {
            const isOverflow = div.scrollHeight > div.clientHeight || div.scrollWidth > div.clientWidth;
            if (!isOverflow && studentsToShow.length > 0) {
                handleRequest(() => {
                    return getStudentsPerPage({
                        grade: researchValues.grade,
                        name: researchValues.name,
                        teacher: researchValues.teacher,
                        startAfterDocId: studentsToShow[studentsToShow.length - 1].docId
                    })
                })
            }
            setIsOverflowing(isOverflow);
        }
    }, [studentsToShow]);

    useEffect(() => {
        const handleScroll = () => {
            const div = divRef.current;
            if (div !== null) {
                const isBottom = div.scrollHeight - div.scrollTop === div.clientHeight;
                setIsAtBottom(isBottom);
            }
        }

        const div = divRef.current;
        if (div !== null) {
            div.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (div !== null) {
                div.removeEventListener('scroll', handleScroll);
            }
        }
    }, []);

    useEffect(() => {
        if (isAtBottom && studentsFromRequest.length > 0 && !isLoading) {
            handleRequest(() => {
                return getStudentsPerPage({
                    grade: researchValues.grade,
                    name: researchValues.name,
                    teacher: researchValues.teacher,
                    startAfterDocId: studentsToShow[studentsToShow.length - 1].docId
                })
            })
        }
    }, [isAtBottom]);

    function loadMoreStudents() {
        handleRequest(() => {
            return getStudentsPerPage({
                grade: researchValues.grade,
                name: researchValues.name,
                teacher: researchValues.teacher,
                startAfterDocId: studentsToShow[studentsToShow.length - 1].docId
            })
        })
    }

    useEffect(() => {
        setIsOverflowing(false);
        setIsAtBottom(false);
        setStudentsToShow([]);

        handleRequest(() => {
            return getStudentsPerPage({
                grade: researchValues.grade,
                name: researchValues.name,
                teacher: researchValues.teacher,
                startAfterDocId: ''
            })
        })
    }, [researchValues]);

    useEffect(() => {
        if (studentsFromRequest.length > 0) {
            setStudentsToShow(prevStudents => {
                const newStudents = studentsFromRequest.filter(newStudent =>
                    !prevStudents.some(existingStudent => existingStudent.docId === newStudent.docId)
                );
                return [...prevStudents, ...newStudents];
            });
        }
    }, [studentsFromRequest]);

    return (
        <div className="student-list" ref={divRef}>
            {studentsToShow.map((studentToShow) => {
                return (
                    <StudentCard key={studentToShow.docId} setStudentSelected={setStudentSelected} student={studentToShow} />
                )
            })}

            {isLoading && studentsFromRequest.length > 0 ? (
                [1, 2, 3, 4, 5, 6].map((index) => {
                    return (
                        <StudentCardLoading key={index} />
                    )
                })
            ) : null}

            {studentsFromRequest.length > 0 ? (
                <div style={{ height: "160px", width: "300px" }} className='d-flex align-items-start justify-content-start p-2'>
                    <button onClick={loadMoreStudents} className='btn btn-primary btn-sm'>Load more...</button>
                </div>
            ) : null}
        </div>
    )
}

import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';

import { useAuthContext } from '../../contexts/AuthContext';

import { IUser } from '../../interfaces/IUser';
import { UsersList } from './UsersList/Index';

import { getUsersPerPage } from '../../services/users';
import { UserForm, TypeUserFormRole } from './UserForm/Index';

import './styles.scss';
import { SpinnerOverModal } from '../../components/Spinner/Index';
import { useHandleApiRequest } from '../../hooks/useHandleApiRequest';

export function UsersPage() {
    const { userHasPermission } = useAuthContext();

    const [emailInSearch, setEmailInSearch] = useState<string>("");
    const [userSelected, setUserSelected] = useState<IUser>();
    const [userFormRole, setUserFormRole] = useState<TypeUserFormRole>();

    useEffect(() => {
        handleGetUsers();
    }, []);

    useEffect(() => {
        if (userSelected) {
            setUserFormRole('profile');
        }
    }, [userSelected]);

    useEffect(() => {
        if (!userFormRole) {
            setUserSelected(undefined);
        }
    }, [userFormRole]);

    const { handleRequest, isLoading, responseData: users = [] } = useHandleApiRequest<IUser[]>();

    async function handleGetUsers() {
        await handleRequest(() => getUsersPerPage({ email: emailInSearch }))
    }

    const handleChangeEmailInSearch = debounce((value: string) => {
        setEmailInSearch(value);
    }, 2000);

    return (
        <>
            {
                isLoading ? (
                    <SpinnerOverModal />
                ) : (
                    <></>
                )
            }

            <UserForm
                refreshResearch={handleGetUsers}
                setUserFormRole={setUserFormRole}
                userFormRole={userFormRole}
                userSelected={userSelected}
            />

            <div className='row'>
                <div className="col">
                    <div className='row'>
                        <div className="col-10 mb-4">
                            <div className="row justify-content-between">
                                <div className="col-9">
                                    <div className="row">
                                        <div className="col">
                                            <label htmlFor="studentNameSearch" className='fs-5'>User email</label>
                                            <input type="text" id="studentNameSearch" className="form-control form-control-sm" placeholder='Ex: John.doe@ear.com.br'
                                                defaultValue={emailInSearch} onChange={(e) => { handleChangeEmailInSearch(e.target.value) }} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-3">
                                    <div className="row justify-items-end">

                                        {
                                            userHasPermission('A1') ? (
                                                <div className="col">
                                                    <div className='blanc'>_</div>
                                                    <button className='btn btn-primary  btn-primary-sm' disabled={userFormRole ? (true) : (false)} onClick={() => { setUserFormRole('add') }}>
                                                        Add User
                                                    </button>
                                                </div>
                                            ) : (
                                                <></>
                                            )
                                        }


                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <UsersList users={users} setUserSelected={setUserSelected} />
                            <div id="emailHelp" className="form-text bg-dark py-1 px-1">
                                there is <strong className='text-white'>{users.length} users </strong>
                                on the list.</div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
import React, { useState, useEffect, useRef } from 'react';
import { IFolder } from '../../../../../interfaces/IFolder';
import folderIcon from '../../../../../assets/folder.svg';
import threeDots from '../../../../../assets/three_dots.svg';
import editIcon from '../../../../../assets/edit_lsa.svg';

import './styles.scss';
import { EditIcon } from '../../../../../Icons/EditIcon';
import { TrashIcon } from '../../../../../Icons/TrashIcon';

interface IStudentFolder {
    folder: IFolder;
    setCurrentParentFolderId: (currentParentFolderId: string) => void;
}

export function FolderListItem({ folder, setCurrentParentFolderId }: IStudentFolder) {
    const [isInEdition, setIsInEdition] = useState<boolean>(false);
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const menuRef = useRef<HTMLDivElement>(null);

    function getFolderContentCount() {
        const folderCount = folder.folders ? folder.folders.length : 0;
        const filesCount = folder.files ? folder.files.length : 0;
        return filesCount + folderCount;
    }

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setIsMenuOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <li className='folder-item'>

            <div className='folder-name d-flex justify-content-start align-items-center w-100' onClick={() => setCurrentParentFolderId(folder.id)}>
                <div className="img-container">
                    <div className='items-count-container' style={{ position: 'absolute', zIndex: '1' }}>
                        <span className="items-count">{getFolderContentCount()}</span>
                    </div>
                    <img src={folderIcon} className='me-2' alt="Folder" />
                </div>
                <div>
                    {isInEdition ? (
                        <input type="text" defaultValue={folder.name} className="form-control form-control-sm me-2" id={`input-f-${folder.id}`} placeholder="Ex: SST" />
                    ) : (
                        <span>{folder.name}</span>
                    )}
                </div>
            </div>

            <div className="menu-container" style={{ position: 'relative' }}>
                <div onClick={() => setIsMenuOpen(!isMenuOpen)} className="d-flex justify-content-center align-items-center menu-icon three-item p-2" >
                    <img src={threeDots} alt="Edit" />
                </div>
                {isMenuOpen && (
                    <div ref={menuRef} className="menu" style={{ position: 'absolute', top: 0, left: '100%', backgroundColor: '#fff', boxShadow: '0px 0px 5px rgba(0,0,0,0.2)', borderRadius: '5px', padding: '5px 0', zIndex: 2 }}>
                        <button type="button" className="gap-2 btn btn-sm btn-outline-secondary">
                            <EditIcon />
                            <span>Edit</span>
                        </button>
                        <button type="button" className="btn btn-sm btn-outline-secondary gap-2">
                            <TrashIcon />
                            <span>Delete</span>
                        </button>
                    </div>
                )}
            </div>
        </li>
    );
}

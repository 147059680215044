import React, { ReactNode } from 'react';
import { useAuthContext } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import { ApiException } from '../interfaces/ApiException';

interface IHomeContext {
    signOut: () => void,
    catchErrorHandled: (error: unknown) => void,
}

const HomeContext = React.createContext<IHomeContext | undefined>(void 0);

interface IHomeProviderProps {
    children: ReactNode,
}

export const HomeProvider = ({ children }: IHomeProviderProps) => {
    const { setUserLogged } = useAuthContext();
    const navigate = useNavigate();

    function catchErrorHandled(error: unknown) {
        console.error(error);
        if (error instanceof Error) {

            alert("Unable to operate the action. Please contact support.");
        } else {
            const errorFromApi = error as ApiException;
            alert(errorFromApi.lsaMessage);
            if (errorFromApi.hasToSignOut) {
                signOut();
            }
        }
    }

    function signOut() {
        setUserLogged(null);
        localStorage.clear();
        return navigate('/login');
    }

    const value = {
        signOut,
        catchErrorHandled
    }

    return (
        <HomeContext.Provider value={value}>
            {children}
        </HomeContext.Provider>
    )
}

export function useHomeContext() {
    const context = React.useContext(HomeContext);

    if (typeof context === 'undefined') {
        throw new Error('useAuthContext must be used within an AuthContext');
    }
    return context;
}
import React from 'react';
import './styles.scss';
import profileIcon from '../../assets/person.svg';
import profileLoading from '../../assets/profileLoading.svg';
import { IStudentBasicInfo } from '../../interfaces/IStudent';
import arrowRightWhite from '../../assets/arrowRightWhite.svg';
import { showValueOrDefaultMessage } from '../../utils/stringUtils';
import { TaskAltIcon } from '../../Icons/TaskAltIcon';

interface IStudentCard {
    student: IStudentBasicInfo;
    setStudentSelected: (student: IStudentBasicInfo) => void,
}

export const StudentCard = React.memo((props: IStudentCard) => {
    return (
        <div className="student-card card " >
            <div className='d-flex align-items-center w-100'>
                <div className='profile p-2 d-flex'>
                    <img src={profileIcon} className="card-img-top me-3" alt="..." />
                    <h5 className="card-title">{props.student.name}</h5>
                </div>
                <button onClick={() => { props.setStudentSelected(props.student) }} type="button" className="btn-arrow btn btn-primary">
                    <img
                        alt='arrow-right'
                        src={arrowRightWhite}
                    />
                </button>
            </div>
            <div className="card-body pt-0">
                <div>
                    <div className='d-flex justify-content-start'>
                        <div className="student-info me-4">
                            <b className='me-2'>Family:</b>
                            <span>{props.student.studentId}</span>
                        </div>
                        <div className="student-info">
                            <b className='me-2'>Grade:</b>
                            <span >{props.student.gradeLevel.level + " " +
                                showValueOrDefaultMessage({
                                    value: props.student.gradeDivision?.division,
                                    defaultMessage: "",
                                })
                            }</span>
                        </div>
                    </div>
                    {/* <div className="student-info mt-2">
                        <b className='me-2'>Teacher:</b>
                        <span>{props.student.teacher.name}</span>
                    </div> */}
                </div>

            </div>
        </div>
    )
})

export function StudentCardLoading() {
    return (
        <div className="student-card student-card-loading card">
            <div className='d-flex align-items-center w-100'>
                <div className='profile p-2 d-flex'>
                    <img src={profileLoading} className="card-img-top me-3" alt="..." />
                    <h5 className="card-title placeholder-glow col-12">
                        <span className="placeholder col-6"></span>
                    </h5>
                </div>
                <button type="button" className="btn-arrow disabled placeholder col-2 btn btn-primary ">

                </button>
            </div>
            <div className="card-body pt-0">
                <div className='d-flex justify-content-start'>
                    <div className="student-info placeholder-glow col-12 me-4">
                        <b className='me-2 placeholder col-4'></b>
                        <span className='placeholder col-4'></span>
                    </div>
                    <div className=" student-info">
                        <b className='me-2'></b>
                        <span ></span>
                    </div>
                </div>
                <div className="student-info placeholder-glow col-12 mt-2">
                    <b className='me-2 placeholder col-4'></b>
                    <span></span>
                </div>

            </div>
        </div>
    )
}
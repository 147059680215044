import React, { useState, useEffect } from "react";
import { gcpKeys } from '../../config/gcpKeys';
import { getTokenFromLocalStorage } from '../../utils/localStorage';
import { AddNewFileIcon } from "../../Icons/AddNewFileIcon";


interface IGooglePicker {
  setSelectedFile: (file: any) => void,
  btnString: string,

}

export function GooglePicker({ setSelectedFile, btnString }: IGooglePicker) {

  const createPicker = () => {

    const picker = new (window as any).google.picker.PickerBuilder()
      .addView(new (window as any).google.picker.DocsView().setIncludeFolders(true))
      .setOAuthToken(getTokenFromLocalStorage().driveAccessToken)
      .setDeveloperKey(gcpKeys.apiKey)
      .setCallback(pickerCallback)
      .build();

    console.dir(picker);

    picker.setVisible(true);
  };

  const pickerCallback = (data: any) => {
    if (data.action === (window as any).google.picker.Action.PICKED) {
      const fileId = data.docs[0].id;
      setSelectedFile(data.docs[0]);
    }
  };


  const handlePickerOpen = () => {
    createPicker();
  };

  return (
    <button className="btn gap-2 btn-sm btn-outline-secondary" onClick={handlePickerOpen}>
      <AddNewFileIcon />
      <span>New file</span>
    </button>
  );
}

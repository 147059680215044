import './styles.scss';
import profileIcon from '../../../../assets/person.svg';
import { DiagnosisPresentation } from './Diagnoses/index';
import { IStudentFullInfo } from '../../../../interfaces/IStudent';
import { replaceUndinedForDash, showValueOrDefaultMessage } from '../../../../utils/stringUtils';
import { orderStudentFAndPQuartes } from '../../../../utils/fAndPUtils';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useState } from 'react';
import { StudentFormContextProvider } from '../../../../contexts/StudentFormContext';
import { UpdateSpecialInfoForm } from '../UpdateSpecialInfoForm/Index';
import { useStudentPageContext } from '../../StudentsPageContext';

interface ISpecialEdInfoProps {
    studentInProfile: IStudentFullInfo,
    refreshData: () => void,
}

export function StudentInfo(props: ISpecialEdInfoProps) {
    const { setStudentSelected } = useStudentPageContext();
    const [isShowingModalContainer, setIsShowingModalContainer] = useState(false);
    const { userHasPermission } = useAuthContext();

    return (
        <div className="card" style={{ width: "18rem" }}>

            <div className="card-body d-flex flex-column justify-content-center align-items-center">
                <div className='d-flex justify-content-start w-100'>
                    <span className="material-symbols-outlined arrow_back" onClick={() => setStudentSelected(undefined)}>
                        arrow_back_ios_new
                    </span>
                </div>
                <img src={profileIcon} className="card-img-top" alt="profile-icon" />
                <h5 className="card-title">{props.studentInProfile.name}</h5>
                <div className='d-flex gap-3'>
                    <span >ID: {props.studentInProfile.studentId}</span>
                    <span >Grade: {props.studentInProfile.gradeLevel.level + " " +
                        showValueOrDefaultMessage({
                            value: props.studentInProfile.gradeDivision?.division,
                            defaultMessage: ""
                        })
                    }</span>
                </div>
            </div>


            <ul className="list-group list-group-flush">
                <li className="d-flex align-items-center justify-content-between list-group-item">
                    <span>Teacher</span>
                    <span className="badge bg-secondary">{showValueOrDefaultMessage({
                        value: props.studentInProfile.teacher?.name,
                        defaultMessage: "Not selected yet",
                    })}</span>
                </li>

                <li className="d-flex align-items-center justify-content-between list-group-item">
                    <span>Follow up</span>

                    {props.studentInProfile.followUp.trim().length > 0 ? (
                        <span className="badge bg-secondary text-wrap">
                            {props.studentInProfile.followUp}
                        </span>
                    ) : (
                        <span className="text-secondary">{"Waiting for update."}</span>
                    )}

                </li>
                <li className="d-flex  flex-wrap align-items-start justify-content-between list-group-item">
                    <DiagnosisPresentation diagnoses={props.studentInProfile.diagnoses} />
                </li>

                <li className='list-group-item'>
                    <div className="mb-4">
                        <span className="">F and P</span>
                        <table className="table">
                            <thead>
                                <tr>
                                    <td scope="col"></td>
                                    <td scope="col">Level</td>
                                    <td scope="col">Classification</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    orderStudentFAndPQuartes({ fAndP: props.studentInProfile.fAndP }).map((quarter, index) => {

                                        return (
                                            <tr key={index}>
                                                <td scope="row">{quarter.presentation}</td>
                                                <td>
                                                    <span className="badge bg-secondary text-wrap">
                                                        {replaceUndinedForDash(quarter.level)}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="badge bg-secondary text-wrap">
                                                        {replaceUndinedForDash(quarter.classification)}
                                                    </span>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </li>
            </ul>



            {
                userHasPermission('E2') ? (
                    <div className="row text-align-left mb-3 mx-0 p-2">


                        <StudentFormContextProvider>
                            <UpdateSpecialInfoForm
                                refreshData={props.refreshData}
                                studentInProfile={props.studentInProfile}
                                isShowingSpecialInfoUpdate={isShowingModalContainer}
                                setIsShowingSpecialInfoUpdate={setIsShowingModalContainer}
                            />
                        </StudentFormContextProvider>

                        <button type="button" className="btn btn-primary btn-sm " onClick={() => { setIsShowingModalContainer(true) }}>
                            Update
                        </button>
                    </div>
                ) : (
                    <></>
                )
            }
        </div>
    )
}
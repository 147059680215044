import { LessonPlanForm } from '../../../../../components/LessonPlanForm/Index';
import { IStudentBasicInfo } from '../../../../../interfaces/IStudent';

interface IAddLessonPlan {
    backToList: () => void,
    student: IStudentBasicInfo,
}

export function AddLessonPlan(props: IAddLessonPlan) {

    return (
        <div>
            <div className='d-flex align-items-center gap-2 mb-3'>
                <span>Complete with <strong>Lesson Plan</strong> information.</span>
            </div>

            <LessonPlanForm student={props.student} backToList={props.backToList} />
        </div>
    )
}
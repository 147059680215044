import React, { useState } from "react";

import fAndPLevels from '../../../../../data/fAndLevels.json';
import fAndPClassifications from '../../../../../data/fAndPClassifications.json';

import { useStudentFormContext } from '../../../../../contexts/StudentFormContext';
import { IStudentFullInfo } from "../../../../../interfaces/IStudent";
import { IFAndPQuarter } from "../../../../../interfaces/IFAndPQuarter";
import { orderStudentFAndPQuartes } from '../../../../../utils/fAndPUtils';


function isValidToShow(value: string) {
  if (typeof value === 'string') {
    const trimValue = value.trim();
    return trimValue.length > 0 && trimValue.toLocaleLowerCase() != "undefined";
  } else {
    return false;
  }
}

interface IFAndPSelection {
  studentInProfile: IStudentFullInfo
}

export function FAndPSelection(props: IFAndPSelection) {

  return (
    <div className="mb-4">
      <label className="form-label mb-0">Fountas and Pinnell</label>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Quarter</th>
            <th scope="col">Level</th>
            <th scope="col">Classification</th>
          </tr>
        </thead>
        <tbody>
          {
            orderStudentFAndPQuartes({ fAndP: props.studentInProfile.fAndP }).map((quarter, index) => {
              console.log('quarterFromUpdate: ', quarter);
              return (
                <tr key={index}>
                  <td scope="row">{quarter.presentation}</td>
                  <td>
                    <LevelSelection quarter={quarter} key={quarter.id} />
                  </td>
                  <td>
                    <ClassificationSelection quarter={quarter} key={quarter.id} />
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </div >
  );
}


function LevelSelection({ quarter }: { quarter: IFAndPQuarter }) {
  const { setValue } = useStudentFormContext();

  function handleOnChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const newLevel = e.target.value;

    switch (quarter.id) {
      case 'quarterOne':
        setValue('fAndP.quarterOne.level', newLevel);
        break;
      case 'quarterTwo':
        setValue('fAndP.quarterTwo.level', newLevel);
        break;
      case 'quarterThree':
        setValue('fAndP.quarterThree.level', newLevel);
        break;
      case 'quarterFour':
        setValue('fAndP.quarterFour.level', newLevel);
        break;
    }
  }

  return (
    <select
      className="form-select form-select-sm"
      aria-label=".form-select-sm example"
      style={{ width: "80px" }}
      onChange={handleOnChange}

    >
      {isValidToShow((quarter).level) ? (
        <option defaultValue={(quarter).level}>
          {(quarter).level}
        </option>
      ) : (
        <option defaultValue={""}>Select level...</option>
      )}

      {fAndPLevels.levels.map((level, index) => {
        return (
          <option value={level} key={index}>
            {level}
          </option>
        );
      })}
    </select>
  )
}

function ClassificationSelection({ quarter }: { quarter: IFAndPQuarter }) {

  const { setValue } = useStudentFormContext();

  function handleOnChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const newClassification = e.target.value;

    switch (quarter.id) {
      case 'quarterOne':
        setValue('fAndP.quarterOne.classification', newClassification);
        break;
      case 'quarterTwo':
        setValue('fAndP.quarterTwo.classification', newClassification);
        break;
      case 'quarterThree':
        setValue('fAndP.quarterThree.classification', newClassification);
        break;
      case 'quarterFour':
        setValue('fAndP.quarterFour.classification', newClassification);
        break;
    }
  }

  return (
    <select
      className="form-select form-select-sm"
      aria-label=".form-select-sm example"
      onChange={handleOnChange}
    >
      {isValidToShow(quarter?.classification) ? (
        <option defaultValue={quarter.classification}>
          {quarter.classification}
        </option>
      ) : (
        <option defaultValue={""}>Select classification...</option>
      )}

      {fAndPClassifications.classifications.map((classification, index) => {
        return (
          <option value={classification} key={index}>
            {classification}
          </option>
        );
      })}
    </select>
  )
}

import React, { useEffect } from "react";
import { gcpKeys } from '../../../config/gcpKeys';


interface IGoogleSign {
    authLogin: ({ googleCredential, driveAccessToken }: { googleCredential: string, driveAccessToken: string }) => void,
    tokenClient: any,
}

export function GoogleSign({ authLogin, tokenClient }: IGoogleSign) {
    useEffect(() => {
        if (tokenClient != undefined) {
            loadGoogleIdentitySign();
        }
    }, [tokenClient]);



    function handleAuthLogin(identitySignResponse: any) {

        const userCredential = identitySignResponse.credential;
        let accessToken: any = null;

        tokenClient.callback = async (response: any) => {
            if (response.error !== undefined) {
                throw (response);
            }
            accessToken = response.access_token;
            authLogin({ driveAccessToken: accessToken, googleCredential: userCredential });
        };

        if (accessToken === null) {
            tokenClient.requestAccessToken({ prompt: 'consent' });
        } else {
            tokenClient.requestAccessToken({ prompt: '' });
        }
    }

    function loadGoogleIdentitySign() {
        (window as any).google.accounts.id.initialize({
            client_id: gcpKeys.clientId,
            callback: handleAuthLogin
        });
        (window as any).google.accounts.id.renderButton(
            document.getElementById('googleSign'),
            { theme: 'outline', size: 'large' }
        );
        (window as any).google.accounts.id.prompt();
    }

    return (
        <div>
            <div id="googleSign"></div>
        </div>
    );
}

import { ApiException } from "../interfaces/ApiException";
import { httpStatus } from "./httpStatus";

export async function throwApiException(response: Response) {
    const responseJson = await response.json();

    const exception: ApiException = {
        lsaMessage: responseJson.lsaMessage,
        status: responseJson.status,
        hasToSignOut: responseJson.status === httpStatus.Unauthorized
    }
    console.log('exp: ', exception);
    throw exception;
}
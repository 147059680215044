import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ModalFade } from '../../../components/ModalFade/Index';


import { addUser, updateUser } from '../../../services/users';
import { useAuthContext } from '../../../contexts/AuthContext';
import { IUser, IUserToSaveInApi } from '../../../interfaces/IUser';
import { SpinnerOverModal } from '../../../components/Spinner/Index';
import { ApiException } from '../../../interfaces/ApiException';
import { useHomeContext } from '../../../contexts/HomeContext';

import roles from '../../../data/roles.json';
import permissions from '../../../data/permissions.json';

interface IUserFormProps {
    setUserFormRole: (value: TypeUserFormRole) => void,
    userFormRole: TypeUserFormRole,
    userSelected?: IUser,
    refreshResearch: ()=>void,
}

export type TypeUserFormRole = 'profile' | 'add' | 'edit' | undefined;

export function UserForm({ setUserFormRole, userFormRole, userSelected, refreshResearch }: IUserFormProps) {

    const { userHasPermission } = useAuthContext();
    const { register, handleSubmit, formState: { errors }, reset, setValue, getValues } = useForm<IUserToSaveInApi>();
    const [isLoading, setIsLoading] = useState(false);
    const { signOut } = useHomeContext();

    useEffect(() => {
        if (userSelected) {
            setValue('email', userSelected.email);

            setValue('role', userSelected.role);

            setValue('permissions', userSelected.permissions ? (userSelected.permissions) : ([]));
        }
    }, [userSelected]);

    useEffect(() => {
        if (userFormRole === "profile")
            toggleFormElements("user-form", false);
        else
            toggleFormElements("user-form", true);
    }, [userFormRole]);

    const toggleFormElements = (formId: string, isEnabled: boolean): void => {
        const form = document.getElementById(formId) as HTMLFormElement | null;

        if (form) {
            const formElements = form.elements;

            for (let i = 0; i < formElements.length; i++) {
                const element = formElements[i] as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

                // Check if the element is not a button with the name 'action-button'
                if (!(element.tagName === 'BUTTON' && element.name === 'action-button')) {
                    element.disabled = !isEnabled;
                }
            }
        }
    };


    async function onSubmit(user: IUserToSaveInApi) {
        setIsLoading(true);
        try {
            if (userFormRole === 'edit') {
                if (userSelected) {
                    await updateUser({
                        docId: userSelected.docId!,
                        user: user,
                    });

                    setUserFormRole('profile');
                    alert('User successfully updated!');
                    refreshResearch();
                }
            } else if (userFormRole === 'add') {

                await addUser({
                    user: user,
                }) as IUser;


                alert('User successfully inserted!');
                reset();
                refreshResearch();
            }
        } catch (err) {
            if (err instanceof Error) {
                alert('Was not possible to add the user. Please, contact the support.')
            } else {
                const errorFromApi = err as ApiException;
                alert(errorFromApi.lsaMessage);
                if (errorFromApi.hasToSignOut) {
                    signOut();
                }
            }
        } finally {
            
            setIsLoading(false);
        }
    }

    const handleCheckboxChange = (permission: string, isChecked: boolean) => {
        const currentPermissions = getValues('permissions');

        if (isChecked) {
            setValue('permissions', Array.from(new Set([...(currentPermissions || []), permission])));
        } else {
            setValue('permissions', currentPermissions?.filter((p) => p !== permission) || []);
        }
    };


    function handleCheckFullPermissionGroup(groupName: string, isChecked: boolean) {
        const checkboxes = document.querySelectorAll(`input[type="checkbox"][name="${groupName}"]`);

        checkboxes.forEach((checkbox) => {
            const x = checkbox as HTMLInputElement;
            x.checked = isChecked;
            handleCheckboxChange(x.value, x.checked);
        });
    }

    function permissionsDefaultChecked(permission: string) {
        const userPermissions = getValues('permissions');
        if (userPermissions) {
            return userPermissions.includes(permission);
        } else {
            return false;
        }
    }

    function handleSetIsShowingForm() {
        reset();
        setUserFormRole(undefined)
    }

    return (
        <ModalFade isShowingModal={userFormRole ? (true) : (false)}>
            <div className="modal-dialog">
                <div className="modal-content">
                    {
                        isLoading ? (
                            <SpinnerOverModal />
                        ) : (
                            <></>
                        )
                    }
                    <div className="modal-header">
                        <h5 className="modal-title">
                            {
                                userFormRole === "add" ? ("Adding user") : (
                                    userFormRole === "edit" ? ("Editing user") : ("User Profile")
                                )
                            }
                        </h5>
                        <button type="button" className="btn-close" aria-label="Close" onClick={handleSetIsShowingForm}></button>
                    </div>

                    <div className="modal-body">
                        <form id="user-form" onSubmit={handleSubmit(onSubmit)} className='form-disabled'>
                            <div className="row mb-3">
                                <div className="col-6">
                                    <label htmlFor="firstName" className="form-label">Email</label>
                                    <input type="text" className={`form-control form-control-sm ${errors.email ? ("is-invalid") : ("")}`}
                                        id="firstName"
                                        {...register("email", {
                                            validate: (value: string) => {
                                                if (!value) {
                                                    return "Invalid email.";
                                                }

                                                if (value.trim().length < 2) {
                                                    return "Invalid email.";
                                                }

                                                return undefined;
                                            }
                                        })}
                                    />
                                    {errors.email && (
                                        <span className="invalid-feedback">{errors.email.message}</span>
                                    )}
                                </div>
                                <div className="col-6">
                                    <label htmlFor="gradeLevel" className="form-label">Role</label>
                                    <select className={`form-select form-select-sm  ${errors.role ? ("is-invalid") : ("")}`} id="gradeLevel"
                                        {...register("role", {
                                            validate: (value: string) => {
                                                if (!value || value.trim() === "") {
                                                    return "Invalid role.";
                                                }

                                                return undefined;
                                            }
                                        })}>
                                        <option selected value="">Select a role...</option>
                                        {
                                            roles.roles.map((role, index) => {
                                                return (
                                                    <option value={role} key={index}>{role}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    {errors.role && (
                                        <span className="invalid-feedback">{errors.role.message}</span>
                                    )}
                                </div>
                            </div>

                            <div className="row mb-3">

                                <div className="col">
                                    <span className="mb-1 d-block">Permissions</span>

                                    <div className="form-check form-check">
                                        <input className="form-check-input" type="checkbox" id="select-all-admin-permissions" value=""
                                            onChange={(e) => handleCheckFullPermissionGroup('admin', e.target.checked)} />
                                        <label className="form-check-label" htmlFor="select-all-admin-permissions">Admin</label>
                                    </div>

                                    <div className='ms-4 mb-2 viewer-permissions'>
                                        {
                                            permissions.permissions.map((permission) => {
                                                if (permission.group === 'admin') {
                                                    return (
                                                        <div className="form-check form-check">
                                                            <input className="form-check-input" name="admin" type="checkbox" id={`admin-${permission.id}`} value={permission.id}
                                                                defaultChecked={permissionsDefaultChecked(permission.id)} onChange={(e) => handleCheckboxChange(e.target.value, e.target.checked)}
                                                            />
                                                            <label className="form-check-label" htmlFor={`admin-${permission.id}`}>{permission.feature}</label>
                                                        </div>
                                                    )
                                                } else {
                                                    return (
                                                        <></>
                                                    )
                                                }
                                            })
                                        }
                                    </div>


                                    <div className="form-check form-check">
                                        <input className="form-check-input" type="checkbox" id="select-all-viewers" value=""
                                            onChange={(e) => handleCheckFullPermissionGroup('viewer', e.target.checked)} />
                                        <label className="form-check-label" htmlFor="select-all-viewers">Viewer</label>
                                    </div>

                                    <div className='ms-4 mb-2 viewer-permissions'>
                                        {
                                            permissions.permissions.map((permission) => {
                                                if (permission.group === 'view') {
                                                    return (
                                                        <div className="form-check form-check">
                                                            <input className="form-check-input" name="viewer" type="checkbox" id={`viewer-${permission.id}`} value={permission.id}
                                                                defaultChecked={permissionsDefaultChecked(permission.id)} onChange={(e) => handleCheckboxChange(e.target.value, e.target.checked)}
                                                            />
                                                            <label className="form-check-label" htmlFor={`viewer-${permission.id}`}>{permission.feature}</label>
                                                        </div>
                                                    )
                                                } else {
                                                    return (<></>)
                                                }
                                            })
                                        }
                                    </div>

                                    <div className="form-check form-check">
                                        <input className="form-check-input" type="checkbox" id="select-all-editors" value="option1"
                                            onChange={(e) => handleCheckFullPermissionGroup('editor', e.target.checked)} />
                                        <label className="form-check-label" htmlFor="select-all-editors">Editor</label>
                                    </div>

                                    <div className='ms-4 editor-permissions'>

                                        {
                                            permissions.permissions.map((permission) => {

                                                if (permission.group === 'edit') {
                                                    return (

                                                        <div className="form-check form-check">
                                                            <input className="form-check-input" name="editor" type="checkbox" id={`editor-${permission.id}`} value={permission.id}
                                                                defaultChecked={permissionsDefaultChecked(permission.id)} onChange={(e) => handleCheckboxChange(e.target.value, e.target.checked)} />
                                                            <label className="form-check-label" htmlFor={`editor-${permission.id}`} >{permission.feature}</label>
                                                        </div>
                                                    )
                                                } else {
                                                    return (<></>)
                                                }

                                            })
                                        }

                                    </div>
                                </div>
                            </div>

                            <div className="d-flex justify-content-end">
                                {
                                    userFormRole === 'profile' ? (
                                        <>
                                            {userHasPermission('A3') ? (
                                                <button onClick={() => { setUserFormRole('edit') }} name="action-button" type="button" className="btn btn-outline-primary">To edit</button>
                                            ) : (
                                                <></>
                                            )}
                                        </>



                                    ) : (
                                        <>
                                            <button name="action-button" type="button" className="btn btn-secondary me-2"
                                                onClick={() => {
                                                    if (userFormRole === 'edit')
                                                        setUserFormRole('profile')
                                                    else
                                                        setUserFormRole(undefined)
                                                }}>Cancel</button>
                                            <button name="action-button" type="submit" className="btn btn-primary">
                                                {
                                                    userFormRole === 'edit' ? ('Save') : ('Add')
                                                }
                                            </button>
                                        </>
                                    )
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </ModalFade>
    )
}
import React from 'react';

import { Table } from '../../../components/Table/Index';
import { IUser } from '../../../interfaces/IUser';



interface IUsersList {
    users: IUser[],
    setUserSelected: (user: IUser) => void,
}

export function UsersList({ users, setUserSelected }: IUsersList) {

    return (
        <Table headList={['Email', 'Role', '']} >
            <>
                {
                    users.length > 0 ? (
                        users.map((user, index) => {
                            return (
                                <tr key={index}>
                                    <td className='align-middle'>{user.email}</td>
                                    <td className='align-middle'>{user.role}</td>
                                    <td>
                                        <button className="btn btn-primary btn-sm" onClick={() => { setUserSelected(user) }}>Profile</button>
                                    </td>
                                </tr>
                            )
                        })
                    ) : (
                        <tr>
                            <td colSpan={5} className='border-bottom-0'>
                                <span className='text-secondary fs-3'>No users found</span>
                            </td>
                        </tr>

                    )
                }
            </>
        </Table >
    )
}
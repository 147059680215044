import './styles.scss';
import { AddDriveIcon } from "../../../../../Icons/AddDriveIcon";
import { AddNewFileIcon } from "../../../../../Icons/AddNewFileIcon";
import { AddNewFolderIcon } from "../../../../../Icons/AddNewFolderIcon";
import { useEffect, useRef, useState } from 'react';
import { GooglePicker } from '../../../../../components/GooglePicker/Index';

interface INewButton {
    setIsCreatingFolder: (value: boolean) => void;
    setSelectedFile: (file: any) => void;
    id: string;
}

export function NewButton(props: INewButton) {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const menuRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setIsMenuOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="add-options" style={{ position: 'relative' }}>
            <button id="new-drive-item" onClick={() => setIsMenuOpen(!isMenuOpen)} type="button" className="text-primary add-drive-btn d-flex align-items-center justify-content-center gap-1 shadow  btn ">
                {<AddDriveIcon />}
                <span>New</span>
            </button>
            {isMenuOpen && (
                <div ref={menuRef} className="menu" style={{ position: 'absolute', top: 0, left: '0%', backgroundColor: '#fff', boxShadow: '0px 0px 5px rgba(0,0,0,0.2)', borderRadius: '5px', padding: '5px 0', zIndex: 2 }}>
                    <button type="button" onClick={() => { props.setIsCreatingFolder(true) }} className="btn gap-2 btn-sm btn-outline-secondary">
                        <AddNewFolderIcon />
                        <span>New folder</span>
                    </button>
                    <GooglePicker
                        btnString='Add file'
                        setSelectedFile={props.setSelectedFile}
                    />
                </div>
            )}
        </div>
    )
}
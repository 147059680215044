export function parseISODateString(isoString: string): Date {
    return new Date(isoString);
}

export function getDateFormatted(date: Date) {
    // Extract year, month, and day from the date
    const year = date.getFullYear();
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();
    
    // Add leading zeros to month and day if necessary
    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }
    const formatted = `${year}-${month}-${day}`;
    
    console.log('formatted: ', formatted);

    // Return the formatted date
    return formatted;
}
import { ModalFade } from '../../../../../components/ModalFade/Index';
import { addFolder } from '../../../../../services/folders';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useHandleApiRequest } from '../../../../../hooks/useHandleApiRequest';
import { SpinnerOverModal } from '../../../../../components/Spinner/Index';

interface ICreateFolderModal {
    setIsShowingModal: (value: boolean) => void,
    reloadFolder: () => void,
    isShowingModal: boolean,
    folderParentId: string,
    studentDocId: string,
}

export function CreateFolderModal(props: ICreateFolderModal) {
    const { setIsShowingModal, folderParentId, studentDocId, reloadFolder } = props;

    const { register, handleSubmit, formState: { errors }, reset } = useForm<{ folderName: string }>();

    const { isLoading, handleRequest } = useHandleApiRequest();

    useEffect(() => {
        if (!props.isShowingModal)
            reset();
    }, [props.isShowingModal]);

    const onSubmit = (data: { folderName: string }) => {

        if (data.folderName.length > 0) {
            handleRequest(() => addFolder({
                folderName: data.folderName,
                folderParentId: folderParentId,
                studentDocId: studentDocId
            })).then(() => {
                setIsShowingModal(false);
                reloadFolder();
                reset();
            })
        } else {
            alert("Folder's name is empty");
        }
    };


    return (
        <>
            {
                isLoading && <SpinnerOverModal />
            }

            <ModalFade setIsShowingModal={setIsShowingModal} isShowingModal={props.isShowingModal}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Folder's name</h5>
                                <button onClick={() => { setIsShowingModal(false) }} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <input {...register("folderName", { required: true })} placeholder='Ex: Diagnostics' type="text" className="mb-1 form-control" aria-describedby="creating-folder" />
                                {errors.folderName && <span className="text-danger">This field is required</span>}
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary">Criar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </ModalFade>
        </>



    )
}

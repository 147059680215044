import React, { useEffect, useState } from 'react';

import './styles.scss';

interface ITable {
    headList: string[]
    children: JSX.Element,
}

export function Table({ headList, children }: ITable) {

    return (
        <div id="table-wrapper">

            <div id="table-scroll">
                <table className="table">
                    <thead className="table-dark">
                        <tr>
                            {headList.map((head, index) => {
                                return (
                                    <th scope="col" key={index} className='text'>{head}</th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {children}
                    </tbody>
                </table>
            </div>


        </div>

    )
}
import './styles.scss';
import { useHomeContext } from '../../contexts/HomeContext';
import { useAuthContext } from '../../contexts/AuthContext';
import { NavLink } from 'react-router-dom';
import arrowDouble from '../../assets/double_arrow.svg';
import { useState } from 'react';

import { HomeIcon } from '../../Icons/HomeIcon';
import { StudentIcon } from '../../Icons/StudentIcon';
import { UsersIcon } from '../../Icons/UsersIcon';
import earEagle from '../../assets/eagle.svg';
interface INavigationLinks {
    path: string,
    displayName: string,
    Icon: JSX.Element,
}


export function Sidebar() {
    const { signOut } = useHomeContext();
    const { userLogged } = useAuthContext();
    const { userHasPermission } = useAuthContext();

    const [isSidebarExpanded, setIsSidebarExpanded] = useState<boolean>(false);

    const navLinksList = [
        // {
        //     displayName: "Dashboard",
        //     path: "/",
        //     Icon: <HomeIcon />,
        // },
        {
            displayName: "Students",
            path: "/",
            Icon: <StudentIcon />,
        }
    ] as INavigationLinks[];

    if (userHasPermission('A2')) {

        navLinksList.push({
            displayName: 'Users',
            path: 'users',
            Icon: <UsersIcon />,
        });
    }

    return (
        <div className={`d-flex flex-column flex-shrink-0 p-3 text-white bg-dark sidebar ${isSidebarExpanded ? ('expanded') : ('')}`}>

            <a href="/" className={`fs-4 title`}>
                {
                    isSidebarExpanded ? (
                        "Learning Support App"
                    ) : (
                        "LSA"
                    )
                }
            </a>
            <hr />
            <ul className="nav nav-pills flex-column mb-auto">
                {
                    navLinksList.map((navLink, index) => {
                        return (
                            <NavLink
                                key={index}
                                to={`${navLink.path}`} className={({ isActive }) =>
                                    isActive ? "nav-link active" : "nav-link"}
                                aria-current="page">

                                {navLink.Icon}

                                {
                                    isSidebarExpanded ? (
                                        navLink.displayName
                                    ) : (
                                        ""
                                    )
                                }

                            </NavLink>
                        )
                    })
                }
            </ul>
            <button className={`arrow-double`} onClick={() => { setIsSidebarExpanded(!isSidebarExpanded) }}>
                <img src={arrowDouble} />
            </button>
            <hr />
            <div className="dropdown">
                <a href="#" className="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src={earEagle} alt="" width="32" height="32" className="rounded-circle me-2" />
                    {
                        isSidebarExpanded ? (
                            <strong>{userLogged?.user.email}</strong>
                        ) : (
                            ""
                        )
                    }
                </a>
                <ul className="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">
                    <li><a className="dropdown-item" href="#" onClick={signOut}>Sign out</a></li>
                </ul>
            </div>
        </div>
    )
}
import './styles.scss';
import { useEffect, useRef, useState } from 'react';
import { useHandleApiRequest } from '../../../../../hooks/useHandleApiRequest'
import { getLessonPlanBySearching } from '../../../../../services/lessonPlans';
import { LessonPlanCard } from './LessonPlanCard/Index';
import { ILessonPlan } from '../../../../../interfaces/ILessonPlan';
import { IStudentBasicInfo } from '../../../../../interfaces/IStudent';
import { StudentCardLoading } from '../../../../../components/StudentCard';

export function LessonPlanList(props: { student: IStudentBasicInfo, endDate: Date | undefined, startDate: Date | undefined}) {
    const divRef = useRef<HTMLDivElement>(null);

    const { isLoading, handleRequest, responseData: studentLessonPlansFromResearch = [] } = useHandleApiRequest<ILessonPlan[]>();
    const [studentLessonPlansToShow, setStudentLessonPlansToShow] = useState<ILessonPlan[]>([]);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const [isAtBottom, setIsAtBottom] = useState(false);

    useEffect(() => {
        const div = divRef.current;
        if (div !== null) {
            const isOverflow = div.scrollHeight > div.clientHeight || div.scrollWidth > div.clientWidth;
            if (!isOverflow && studentLessonPlansToShow.length > 0) {
                handleRequest(() => {
                    return getLessonPlanBySearching({
                        student: props.student,
                        startAfterDocId: studentLessonPlansToShow[studentLessonPlansToShow.length - 1].docId,
                        endDate: props.endDate,
                        startDate: props.startDate,
                    })
                })
            }
            setIsOverflowing(isOverflow);
        }
    }, [studentLessonPlansToShow]);

    useEffect(() => {
        const handleScroll = () => {
            const div = divRef.current;
            if (div !== null) {
                const isBottom = div.scrollHeight - div.scrollTop === div.clientHeight;
                setIsAtBottom(isBottom);
            }
        }

        const div = divRef.current;
        if (div !== null) {
            div.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (div !== null) {
                div.removeEventListener('scroll', handleScroll);
            }
        }
    }, []);

    useEffect(() => {
        if (isAtBottom && studentLessonPlansFromResearch.length > 0 && !isLoading) {
            handleRequest(() => {
                return getLessonPlanBySearching({
                    student: props.student,
                    startAfterDocId: studentLessonPlansToShow[studentLessonPlansToShow.length - 1].docId,
                    endDate: props.endDate,
                    startDate: props.startDate
                })
            })
        }
    }, [isAtBottom]);

    function loadMoreStudents() {
        handleRequest(() => {
            return getLessonPlanBySearching({
                student: props.student,
                startAfterDocId: studentLessonPlansToShow[studentLessonPlansToShow.length - 1].docId,
                endDate: props.endDate,
                startDate: props.startDate
            })
        })
    }

    useEffect(() => {
        setIsOverflowing(false);
        setIsAtBottom(false);
        setStudentLessonPlansToShow([]);

        handleRequest(() => {
            return getLessonPlanBySearching({
                student: props.student,
                startAfterDocId: '',
                endDate: props.endDate,
                startDate: props.startDate
            })
        })
    }, [props.startDate, props.endDate]); //enable research by date right here

    useEffect(() => {
        if (studentLessonPlansFromResearch.length > 0) {
            setStudentLessonPlansToShow(prevLessonPlans => {
                const newLessonPlans = studentLessonPlansFromResearch.filter(newStudent =>
                    !prevLessonPlans.some(existingStudent => existingStudent.docId === newStudent.docId)
                );
                return [...prevLessonPlans, ...newLessonPlans];
            });
        }
    }, [studentLessonPlansFromResearch]);

    return (
        <div className="d-flex flex-column p-2 gap-4 lesson-plan-list mt-4" ref={divRef}>
            {studentLessonPlansToShow.map((lessonPlanToShow) => {
                return (
                    <LessonPlanCard key={lessonPlanToShow.docId}  lessonPlan={lessonPlanToShow} />
                )
            })}

            {isLoading && studentLessonPlansFromResearch.length > 0 ? (
                [1, 2, 3, 4, 5, 6].map((index) => {
                    return (
                        <StudentCardLoading key={index} />
                    )
                })
            ) : null}

            {studentLessonPlansFromResearch.length > 0 ? (
                <div style={{ height: "160px", width: "300px" }} className='d-flex align-items-start justify-content-start p-2'>
                    <button onClick={loadMoreStudents} className='btn btn-primary btn-sm'>Load more...</button>
                </div>
            ) : null}
        </div>
    )
}
import React from 'react';
import './styles/global.scss';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { LoadGoogleScripts } from './utils/LoadGoogleScripts';

import { Login } from './pages/Login/Index';

import { Root } from './pages/Root/Index';

import { StudentsPage } from './pages/StudentsPage/Index';
import { UsersPage } from './pages/UsersPage/Index';


const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "users",
        element: <UsersPage />,
      },
      {
        index: true,
        element: <StudentsPage />
      }
    ],
  }, {
    path: "/login",
    element: <Login />
  }
]);


ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <AuthProvider>
    <LoadGoogleScripts />
    <RouterProvider router={router} />
  </AuthProvider>
);

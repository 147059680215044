import React from "react";

import followUps from "../../../../../data/followUps.json";
import { useStudentFormContext } from "../../../../../contexts/StudentFormContext";


export function FollowUpSelection({
  currentFollowUp,
}: {
  currentFollowUp: string;
}) {
  const { setValue } = useStudentFormContext();

  return (
    <div className="mb-4">
      <label htmlFor="followUpSelect" className="form-label ">
        Follow UP
      </label>
      <select
        id="followUpSelect"
        className="form-select form-select-sm"
        aria-label="Default select example"
        onChange={(e) => {
          setValue('followUp', e.target.value);
        }}
      >
        {currentFollowUp.trim().length > 0 ? (
          <option value={currentFollowUp}>{currentFollowUp}</option>
        ) : (
          <option value={undefined}>Select a follow up...</option>
        )}

        {followUps.followUps.map((followUp, index) => {
          if (
            followUp.trim().toUpperCase() !=
            currentFollowUp.trim().toUpperCase()
          ) {
            return (
              <option value={followUp} key={index}>
                {followUp}
              </option>
            );
          }
        })}
      </select>
    </div>
  );
}


import { IFileBasicInfo } from "../interfaces/IFile";

import { getTokenFromLocalStorage } from "../utils/localStorage";
import { throwApiException } from "../utils/throwApiException";

export async function addFile({ studentDocId, folderParentId, newFile }: { studentDocId: string, folderParentId: string, newFile: IFileBasicInfo }) {

    const response = await fetch(`${process.env.REACT_APP_API_CURRENT_URL}/files/add/${studentDocId}/${folderParentId}`, {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${getTokenFromLocalStorage().specialEdToken}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            newFile: newFile
        })
    });

    if (!response.ok) {
        await throwApiException(response);
    }

}

import React from 'react';

import './styles.scss';


export function Spinner() {
    return (
        <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    )
}

export function SpinnerOverModal() {
    return (
        <div className="spinner-over-modal">
            <div className="my-spinner"></div>
            <div className="black-eagle"></div>
        </div>
    )
}

import React, { useEffect } from 'react';

import { useAuthContext } from "../contexts/AuthContext";

import { gcpKeys } from '../config/gcpKeys';

export function LoadGoogleScripts() {
  const { setTokenClient } = useAuthContext();

  useEffect(() => {
    const gapiScript = document.createElement("script");
    gapiScript.async = true;
    gapiScript.defer = true;
    gapiScript.src = "https://apis.google.com/js/api.js";
    gapiScript.onload = gapiLoaded;

    const gisScript = document.createElement("script");
    gisScript.async = true;
    gisScript.defer = true;
    gisScript.src = "https://accounts.google.com/gsi/client";
    gisScript.onload = gisLoaded;

    const body = document.getElementsByTagName("body")[0];
    body.appendChild(gapiScript);
    body.appendChild(gisScript);
  }, []);

  function gapiLoaded() {
    (window as any).gapi.load("client:picker", initializePicker);
  }

  async function initializePicker() {
    await (window as any).gapi.client.load(
      "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"
    );
  }

  function gisLoaded() {
    const tokenClient = (window as any).google.accounts.oauth2.initTokenClient({
      client_id: gcpKeys.clientId,
      scope: gcpKeys.scopes,
      callback: "",
    });
    setTokenClient(tokenClient);
  }

  return (
    <></>
  )
}


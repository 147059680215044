import React, { useEffect, useState } from "react";
import { IStudentFullInfo } from "../../../../interfaces/IStudent";
import { DiagnosesSelection } from "./DiagnosesSelection/Index";
import { FollowUpSelection } from "./FollowUpSelection/Index";
import { FAndPSelection } from "./FAndPSelection/Index";

import { useStudentFormContext } from "../../../../contexts/StudentFormContext";
import { updateStudentByDocId } from '../../../../services/students';

import { ModalFade } from '../../../../components/ModalFade/Index';

import { SpinnerOverModal } from '../../../../components/Spinner/Index';
import { useHandleApiRequest } from "../../../../hooks/useHandleApiRequest";

import grades from '../../../../data/grades.json';
import { getTeachers } from '../../../../services/teacher';

import './styles.scss';
import { ITeacher } from "../../../../interfaces/ITeacher";

interface IUpdateSpecialInfoFormProps {
  refreshData: () => void,
  studentInProfile: IStudentFullInfo,
  isShowingSpecialInfoUpdate: boolean;
  setIsShowingSpecialInfoUpdate: (value: boolean) => void,
}

export function UpdateSpecialInfoForm(props: IUpdateSpecialInfoFormProps) {
  const { handleSubmit, register } = useStudentFormContext();

  const { isLoading, handleRequest } = useHandleApiRequest();
  const { handleRequest: handleGetTeachers, responseData: teachers = [] } = useHandleApiRequest<ITeacher[]>();

  useEffect(() => {
    handleGetTeachers(() => {
      return getTeachers()
    })
  }, []);



  const onSubmit = (student: IStudentFullInfo) => {

    handleRequest(() => updateStudentByDocId({ docId: props.studentInProfile.docId, student: student }), "Student successfully updated").then(() => {
      props.refreshData();
    })

  };


  return (
    <>
      <ModalFade
        isShowingModal={props.isShowingSpecialInfoUpdate}
      >
        <div className="modal-dialog ">
          <div className="modal-content edition-model-content">
            {
              isLoading && <SpinnerOverModal />
            }
            <div className="modal-header justify-content-center">
              <h5 className="modal-title text-center">{props.studentInProfile.name + " - " + props.studentInProfile.gradeLevel.level}</h5>
              <button type="button" className="btn-close" aria-label="Close" onClick={() => { props.setIsShowingSpecialInfoUpdate(false) }}></button>
            </div>

            <div className="modal-body ">
              <form onSubmit={handleSubmit(onSubmit)}>

                <div className="student-edition-form pe-3">
                  <div className="row mb-3">

                    <div className="col-3">
                      <label htmlFor="student-id-edition" className="form-label">Student ID</label>
                      <input
                        defaultValue={props.studentInProfile.studentId}
                        type="text"
                        className="form-control form-control-sm" id="student-id-edition" placeholder="Ex: 0000/1"
                        {...register("studentId", {
                          validate: (value: string) => {
                            if (!value) {
                              return "Invalid student id format.";
                            }

                            const regex = /^[0-9]+\/[0-9]+$/;
                            if (!regex.test(value.trim())) {
                              return "Invalid student id format.";
                            }
                            return undefined;
                          }
                        })}
                      />
                    </div>

                    <div className="col">
                      <label htmlFor="student-name-edition" className="form-label">Full Name</label>
                      <input defaultValue={props.studentInProfile.name} type="text" className="form-control form-control-sm" id="student-name-edition" placeholder="Ex: John"
                        {...register("name", {
                          validate: (value: string) => {
                            if (!value) {
                              return "Invalid first name.";
                            }

                            if (value.trim().length < 2) {
                              return "Invalid first name.";
                            }

                            return undefined;
                          }
                        })}
                      />
                    </div>


                  </div>

                  <div className="row mb-3">
                    <div className="col-6">
                      <label htmlFor="student-name-edition" className="form-label">Grade Level</label>

                      <div className="d-flex">
                        <select className="form-select form-select-sm" aria-label=".form-select-sm "
                          {
                          ...register('gradeLevel.docId')
                          }
                        >

                          <option selected={props.studentInProfile.gradeLevel?.docId?.trim().length <= 0} value={""}>Select</option>

                          {
                            grades.gradeLevels.map((gradeLevel) => {
                              return (
                                <option selected={props.studentInProfile.gradeLevel.docId == gradeLevel.docId}
                                  value={gradeLevel.docId}>{gradeLevel.level}</option>
                              )
                            })
                          }

                        </select>

                      </div>

                    </div>

                    <div className="col-6">
                      <label htmlFor="student-name-edition" className="form-label">Grade Division</label>

                      <div className="d-flex">
                        <select className="form-select form-select-sm" aria-label=".form-select-sm "
                          {
                          ...register('gradeDivision.docId')
                          }
                        >

                          <option selected={props.studentInProfile.gradeDivision && props.studentInProfile.gradeDivision.docId?.trim().length <= 0} value={""}>Select</option>
                          {
                            grades.gradeDivisions.map((gradeDivision) => {
                              return (
                                <option selected={props.studentInProfile.gradeDivision?.docId == gradeDivision.docId} value={gradeDivision.docId}>{gradeDivision.division}</option>
                              )
                            })
                          }
                        </select>

                      </div>
                    </div>

                  </div>

                  <div className="row mb-3">
                    <div className="col">
                      <label htmlFor="student-name-edition" className="form-label">Support Teacher</label>

                      <select className="form-select form-select-sm" aria-label=".form-select-sm"
                        {
                        ...register('teacher.docId')
                        }
                      >
                        <option selected={props.studentInProfile.teacher && props.studentInProfile.teacher.docId?.trim().length <= 0} value={""}>Select</option>
                        {
                          teachers.map((teacher) => {
                            return (
                              <option selected={props.studentInProfile.teacher?.docId == teacher.docId} value={teacher.docId}>{teacher.name} - {teacher.grades}</option>
                            )
                          })
                        }

                      </select>
                    </div>
                  </div>


                  <div className="row">
                    <div className="col">
                      <DiagnosesSelection diagnoses={props.studentInProfile.diagnoses} />
                    </div>
                    <div className="col">
                      <FollowUpSelection currentFollowUp={props.studentInProfile.followUp} />
                    </div>
                  </div>

                  <FAndPSelection studentInProfile={props.studentInProfile} />

                </div>

                <hr />

                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={handleSubmit(onSubmit)}
                >
                  Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </ModalFade>

    </>
  );
}

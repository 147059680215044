import React, { useEffect, useState } from 'react';

import './styles.scss';

import { StudentCard } from '../../../components/StudentCard/index';
import { useStudentPageContext } from '../StudentsPageContext';
import { LoadingAnimation } from '../../../components/LoadingAnimation';
import { areAllPropertiesEmpty } from '../../../utils/stringUtils'
import { IStudentBasicInfo } from '../../../interfaces/IStudent';

import { LatestStudents } from './LatestStudents/index';
import { StudentsFromResearch } from './StudentsFromResearch/index';

export function StudentsList() {

    // function isToShowLatestStudents() {
    //     return (!studentsToShow || studentsToShow.length <= 0) && areAllPropertiesEmpty(researchValues);
    // }


    return (
        <div className='student-list-container'>
            <h4>Current research</h4>
            <StudentsFromResearch />
        </div >
    )
}

//V1:
{/* <div className='student-list-container'>
            {
                isSearchingForStudent && <>
                    <h4 className='mb-4'>Looking for students...</h4>
                    <LoadingAnimation />
                </>
            }
            {
                isToShowLatestStudents() ? (
                    <LatestStudents />
                ) : (
                    <>
                        <h4>Current research</h4>
                        <StudentsFromResearch />
                    </>
                )
            }
        </div > */}
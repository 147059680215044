import { Outlet } from 'react-router-dom';
import { Sidebar } from '../../components/Sidebar/Index';
import './styles.scss';
import { useUserAuthentication } from '../../hooks/useUserAuthentication';
import { HomeProvider } from '../../contexts/HomeContext';
import { SpinnerOverModal } from '../../components/Spinner/Index';

export function Root() {
    const { isLoadingUser } = useUserAuthentication();

    return (
        <>
            {isLoadingUser ? (
                <div>
                    <SpinnerOverModal />
                </div>
            ) : (
                <HomeProvider>
                    <div className='page-container'>
                        <Sidebar />
                        <div className="container pt-3">
                            <Outlet />
                        </div>
                    </div >
                </HomeProvider>
            )
            }
        </>

    )
}

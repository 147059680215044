import { debounce } from "lodash";
import grades from '../../../data/grades.json';
import { useStudentPageContext, IResearch } from '../StudentsPageContext';
import { IGradeLevel } from "../../../interfaces/IGradeLevel";

export function StudentResearch() {
    const { researchValues, setResearchValues } = useStudentPageContext();

    const handleChangeNameInSearch = debounce((value: string) => {
        const newValue: IResearch = {
            ...researchValues,
            name: value,
        }
        setResearchValues(newValue);
    }, 200);

    const handleChangeGradeInSearch = debounce((value: IGradeLevel) => {
        const newValue: IResearch = {
            ...researchValues,
            grade: value
        }
        setResearchValues(newValue);
    }, 200);

    return (
        <div className="row">
            <div className="col">
                <label htmlFor="studentNameSearch" className='fs-5'>Student</label>
                <input type="text" id="studentNameSearch" onChange={(e) => { handleChangeNameInSearch(e.target.value) }} className="form-control " placeholder='Ex: John Doe'
                    defaultValue={researchValues.name} />
            </div>

            <div className="col-3">
                <label htmlFor="studentGradeSearch" className='fs-5'>Grade</label>
                <select id="studentGradeSearch" onChange={(e) => {
                    handleChangeGradeInSearch({
                        docId: e.target.value,
                        level: "",
                    })
                }} className="form-select " aria-label="">
                    <option selected={researchValues.grade.docId.length <= 0} value="">All</option>
                    {
                        grades.gradeLevels.map((grade, index) => {
                            return (
                                <option selected={grade.docId == researchValues.grade.docId} value={grade.docId} key={index}>{grade.level}</option>
                            )
                        })
                    }
                </select>
            </div>
        </div>
    )
}
import { httpStatus } from '../utils/httpStatus';
import { IUser } from '../interfaces/IUser';
import { ApiException } from '../interfaces/ApiException';

import { getTokenFromLocalStorage } from '../utils/localStorage';

async function getEmailFromGoogleCredential(googleCredential: string) {

    const fetchResponse = await fetch(`${process.env.REACT_APP_API_CURRENT_URL}/getEmailFromGoogleCredential`, {
        method: 'GET',
        headers: {
            'Authorization': googleCredential
        },
    });

    const responseJson = await fetchResponse.json();

    if (responseJson.status !== httpStatus.success) {
        const exception: ApiException = {
            lsaMessage: responseJson.lsaMessage,
            status: responseJson.status,
            hasToSignOut: responseJson.status === httpStatus.Unauthorized
        }
        throw exception;
    }

    return {
        email: responseJson.email
    };
}

async function validateEmailAndGetUserWithToken(email: string) {

    const fetchResponse = await fetch(`${process.env.REACT_APP_API_CURRENT_URL}/validateEmailAndGetUserWithToken/${email}`, {
        method: 'GET',
    });

    const responseJson = await fetchResponse.json();

    if (responseJson.status !== httpStatus.success) {
        const exception: ApiException = {
            lsaMessage: responseJson.lsaMessage,
            status: responseJson.status,
            hasToSignOut: responseJson.status === httpStatus.Unauthorized
        }
        throw exception;
    }

    const user: IUser = responseJson.user;
    const specialEdToken: string = responseJson.token;

    return {
        user, specialEdToken
    };
}

async function getUserByToken() {

    const fetchResponse = await fetch(`${process.env.REACT_APP_API_CURRENT_URL}/getUserByToken`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${getTokenFromLocalStorage().specialEdToken}`
        },
    });

    const responseJson = await fetchResponse.json();

    if (responseJson.status !== httpStatus.success) {
        const exception: ApiException = {
            lsaMessage: responseJson.lsaMessage,
            status: responseJson.status,
            hasToSignOut: responseJson.status === httpStatus.Unauthorized
        }
        throw exception;
    }

    const user: IUser = responseJson.user;

    return { user };
}

export { getEmailFromGoogleCredential, validateEmailAndGetUserWithToken, getUserByToken }
import { IStudentBasicInfo } from '../../../../interfaces/IStudent';
import { LessonPlanList } from './LessonPlanList/Index';
import { AddLessonPlan } from './AddLessonPlan/Index'
import { useEffect, useState } from 'react';
import {removeTimeFromDateTime} from '../../../../utils/stringUtils';

export function LessonPlanArea(props: { student: IStudentBasicInfo }) {

    const [isAddingLessonPlan, setIsAddingLessonPlan] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<Date | undefined>();
    const [endDate, setEndtDate] = useState<Date | undefined>();

    function handleSetStartDate(dateFromInput:string){
        setStartDate(dateFromInput.trim().length > 0 ? (new Date(dateFromInput+"T00:00:00-03:00")):(undefined));
    }

    function handleSetEndDate(dateFromInput:string){
        setEndtDate(dateFromInput.trim().length > 0 ? (new Date(dateFromInput+"T00:00:00-03:00")):(undefined));
    }

    return (
        <div className="lesson-plan-area mb-2 mt-4">
            {
                isAddingLessonPlan ? (
                    <AddLessonPlan student={props.student} backToList={()=>{setIsAddingLessonPlan(false)}}/>
                ) : (
                    <>
                        <div className="d-flex justify-content-between align-items-center ">
                            <button className="btn btn-primary" onClick={()=>{setIsAddingLessonPlan(true)}}>
                                New Lesson Plan
                            </button>

                            <div className='d-flex  align-items-center gap-4 date-range'>
                                <div className="d-flex align-items-center gap-2">
                                    <label htmlFor="lesson-plan-date" className="form-label m-0">From</label>
                                    <input onChange={(e)=>handleSetStartDate(e.target.value)} type="date" className="form-control" id="lesson-plan-date" aria-describedby="lesson-plan-date" />
                                </div>
                                <div className="d-flex align-items-center gap-2">
                                    <label htmlFor="lesson-plan-date" className="form-label m-0">To</label>
                                    <input onChange={(e)=>handleSetEndDate(e.target.value)} type="date" className="form-control" id="lesson-plan-date" aria-describedby="lesson-plan-date" />
                                </div>
                            </div>;
                        </div>

                        <LessonPlanList endDate={endDate} startDate={startDate} student={props.student} />
                    </>
                )
            }

        </div>
    )
}
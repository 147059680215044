import React, { useEffect, useState } from 'react';

import { FolderListItem } from './FolderListItem/index';
import { FileListItem } from './FileListItem/index';

import './styles.scss';
import { IFolder } from '../../../../interfaces/IFolder';

import { IFileBasicInfo, IFileFullInfo } from '../../../../interfaces/IFile';

import { FileViewFullScreen } from './FileViewFullScreen/Index';

import { CreateFolderModal } from './CreateFolderModal/index';
import { IStudentFullInfo } from '../../../../interfaces/IStudent';
import { getFolder } from '../../../../services/folders';
import { LoadingAnimation } from '../../../../components/LoadingAnimation';
import { addFile } from '../../../../services/file';
import { useHandleApiRequest } from '../../../../hooks/useHandleApiRequest';

import { NewButton } from './NewButton/index';

import noDataSvg from '../../../../assets/no_data.svg';

interface StudentInProfile {
    studentInProfile: IStudentFullInfo
}
export function StudentDocuments(props: StudentInProfile) {
    const [foldersTree, setFoldersTree] = useState<IFolder[]>([]);
    const [fileInView, setFileInView] = useState<IFileFullInfo | undefined>();
    const [isCreatingFolder, setIsCreatingFolder] = useState<boolean>(false);
    const [currentParentFolderId, setCurrentParentFolderId] = useState<string>("0");

    const { isLoading, handleRequest, responseData: currentParentFolder } = useHandleApiRequest<IFolder>();

    useEffect(() => {
        handleGetFolder();
    }, [currentParentFolderId]);

    async function handleGetFolder() {
        handleRequest(() => getFolder({
            folderId: currentParentFolderId,
            studentDocId: props.studentInProfile.docId
        }));
    }

    useEffect(() => {
        if (currentParentFolder) {
            handleSetFoldersTree(currentParentFolder);
        }
    }, [currentParentFolder]);

    async function handleAddFileInFolder(file: any) {
        const fileToAdd: IFileBasicInfo = {
            id: file.id,
            linkUrl: file.linkUrl,
        }

        await addFile({
            newFile: fileToAdd,
            folderParentId: currentParentFolderId,
            studentDocId: props.studentInProfile.docId,
        });
        alert('File successfully added to student');

        handleGetFolder();
    }

    function handleSetFoldersTree(currentParentFolder: IFolder) {
        if (currentParentFolder.id != "0") {
            const alreadyRegisteredInTree = foldersTree.filter((folderFromTree) => folderFromTree.id === currentParentFolder.id).length > 0;
            if (alreadyRegisteredInTree) {
                const index = foldersTree.indexOf(currentParentFolder);
                if (index !== -1) {
                    const arrayCut = foldersTree.slice(0, index + 1);
                    setFoldersTree(arrayCut);
                }
            }
            else {
                setFoldersTree(current => [...current, currentParentFolder])
            }
        } else {
            setFoldersTree([]);
        }
    }

    return (
        <div className="student-documents">

            <CreateFolderModal
                setIsShowingModal={setIsCreatingFolder}
                isShowingModal={isCreatingFolder}
                folderParentId={currentParentFolder ? (currentParentFolder.id) : ("0")}
                studentDocId={props.studentInProfile.docId}
                reloadFolder={handleGetFolder}
            />

            <div className="mb-2 mt-4 folder-tree">
                <span className="me-3 h6"
                    onClick={() => {
                        setCurrentParentFolderId("0");
                        setFileInView(undefined);
                    }}
                >
                    Root
                </span>

                {
                    foldersTree.length > 0 ? (
                        <span className='me-3 fw-bold'>
                            {">"}
                        </span>
                    ) : (<></>)
                }

                <span>
                    {
                        foldersTree.map((folderFromTree, index) => {
                            const isTheLastOne = foldersTree.length - 1 === index;
                            return (
                                <span
                                    key={folderFromTree.id}
                                    className={`folder-tree-item me-3 ${isTheLastOne ? ("fw-bold") : ("text-secondary")}`} onClick={() => {
                                        setCurrentParentFolderId(folderFromTree.id);
                                        handleSetFoldersTree(folderFromTree);
                                        setFileInView(undefined);
                                    }}>
                                    {folderFromTree.name} {">"}


                                </span>
                            )

                        })
                    }
                </span>
            </div>

            {
                fileInView ? (
                    <></>
                ) : (
                    <>

                        <div className="mt-3 mb-4">
                            <NewButton id="new-drive-item" setSelectedFile={handleAddFileInFolder} setIsCreatingFolder={setIsCreatingFolder} />
                        </div>
                    </>

                )
            }

            {
                fileInView ? (
                    <FileViewFullScreen
                        closeModal={() => setFileInView(undefined)}
                        fileInView={fileInView}
                    />
                ) : (
                    <div className="documents-list">
                        {
                            isLoading ? (
                                <LoadingAnimation />
                            ) : (
                                <>
                                    {
                                        currentParentFolder ? (

                                            <>
                                                {
                                                    currentParentFolder.files.length > 0 || currentParentFolder.folders.length > 0 ? (
                                                        <ul className="folders-list">
                                                            {currentParentFolder.folders.map((folder) => {
                                                                return (
                                                                    <FolderListItem
                                                                        key={folder.id}
                                                                        folder={folder}
                                                                        setCurrentParentFolderId={setCurrentParentFolderId}
                                                                    />
                                                                )
                                                            })}
                                                            {currentParentFolder.files.map((file) => {
                                                                return (
                                                                    <FileListItem
                                                                        key={file.id}
                                                                        file={file}
                                                                        setFileInView={setFileInView}
                                                                    />
                                                                )
                                                            })}
                                                        </ul>
                                                    ) : (
                                                        <div className='d-flex gap-3 justify-content-center align-items-center no-data'>
                                                            <img src={noDataSvg} alt="no-data" />
                                                            <div className='text-center'>
                                                                <h5 className=''>
                                                                    No data found
                                                                </h5>
                                                                <h6 className='text-secondary'>
                                                                    Use the <span onClick={() => {
                                                                        document.getElementById('new-drive-item')?.click();
                                                                    }} style={{ cursor: "pointer" }} className='text-primary'>"New"</span> button to add.
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </>



                                        ) : (
                                            <></>
                                        )
                                    }
                                </>
                            )
                        }



                    </div>
                )
            }

        </div >
    )
}
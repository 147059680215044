import React from 'react';

import { StudentPageContextProvider } from './StudentsPageContext';
import { StudentPageContent } from './StudentPageContent/index';

export function StudentsPage() {
    return (
        <StudentPageContextProvider>
            <StudentPageContent />
        </StudentPageContextProvider>
    )
}
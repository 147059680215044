import { useState } from "react"
import { ILessonPlan, IObservation } from "../../interfaces/ILessonPlan"
import { Controller, useForm } from "react-hook-form";
import { useHandleApiRequest } from "../../hooks/useHandleApiRequest";
import { addLessonPlan } from '../../services/lessonPlans';
import { IStudentBasicInfo } from "../../interfaces/IStudent";

interface ILessonPlanForm {
    prevLessonPlanData?: ILessonPlan,
    backToList: () => void,
    student: IStudentBasicInfo,
}

interface ILessonPlanFormDefaultValues {
    count?: number | undefined;
    day?: string | undefined;
    subject?: string | undefined;
    room?: string | undefined;
    observations?: (string | undefined)[] | undefined;
    customObservation?: string | undefined;
    comments?: string | undefined;
}

export function LessonPlanForm(props: ILessonPlanForm) {
    const isAllDisabled = props.prevLessonPlanData ? true : false;

    const { isLoading, handleRequest: addLessonPlanRequest, responseData } = useHandleApiRequest<void>();

    const { control, register, handleSubmit } = useForm<ILessonPlanFormDefaultValues>({
        defaultValues: {
            count: props.prevLessonPlanData?.count,
            day: props.prevLessonPlanData ? getFormatDateToInput(props.prevLessonPlanData.day.toDateString()) : getCurrentDateInUTC3(),
            subject: props.prevLessonPlanData?.subject,
            room: props.prevLessonPlanData?.room.id,
            observations: props.prevLessonPlanData?.observations.map(obs => obs.id) || [],
            customObservation: getCustomObservation(),
            comments: props.prevLessonPlanData?.comments,
        }
    });

    function getFormatDateToInput(dateString: string | undefined): string {
        const date = new Date(dateString ? dateString : "");
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    function getCurrentDateInUTC3(): string {
        const date = new Date();
        date.setHours(date.getHours() - 3); // Adjust to UTC-3
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    function getCustomObservation() {
        const obsList = props.prevLessonPlanData?.observations;
        if (obsList) {
            const observationsFiltered = obsList.filter((obs) => obs.id == '6');
            return observationsFiltered.length > 0 ? observationsFiltered[0].observation : '';
        } else {
            return '';
        }
    }

    const onSubmit = (data: ILessonPlanFormDefaultValues) => {
        const observationsToStore: IObservation[] = [];

        if (data.observations) {
            data.observations.forEach((item) => {
                if (item) {
                    observationsToStore.push({
                        id: item,
                        observation: item == '6' ? data.customObservation ? data.customObservation : '' : ''
                    })
                }
            })
        }

        const lessonPlanToAdd: ILessonPlan = {
            docId: '',
            comments: data.comments ? data.comments : "",
            count: data.count ? data.count : 0,
            day: data.day ? new Date(data.day) : new Date(),
            observations: observationsToStore,
            room: data.room ? {
                id: data.room,
                room: ''
            } : {
                id: '',
                room: ''
            },
            student: props.student,
            subject: data.subject ? data.subject : ''
        }

        addLessonPlanRequest(() => addLessonPlan(lessonPlanToAdd), "Lesson plan successfully inserted").then(() => { props.backToList() })
    };

    function handleCheckCustomObs() {
        const checkCustomObsElement = document.getElementById('lesson-plan-occurrence-custom') as HTMLInputElement;
        if (!isAllDisabled) {
            checkCustomObsElement.checked = true;
        }
    }

    return (
        <div className="card" style={{ width: "12rem;" }}>
            <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row mb-4">
                        <div className="col-2">
                            <label htmlFor="lesson-plan-number" className="form-label">Nº</label>
                            <input disabled={true} type="text" defaultValue={props.prevLessonPlanData?.count ? (props.prevLessonPlanData?.count) : ("Auto")} className="form-control" id="lesson-plan-number" aria-describedby="lesson-plan-number" />
                        </div>
                        <div className="col-3">
                            <label htmlFor="lesson-plan-date" className="form-label">Data</label>
                            <Controller
                                control={control}
                                name="day"
                                render={({ field }) => (
                                    <input {...field} disabled={isAllDisabled} type="date" className="form-control" id="lesson-plan-date" aria-describedby="lesson-plan-date" />
                                )}
                            />
                        </div>
                        <div className="col">
                            <label htmlFor="lesson-plan-subject" className="form-label">Matéria</label>
                            <input {...register('subject')} disabled={isAllDisabled} type="text" className="form-control" id="lesson-plan-subject" aria-describedby="lesson-plan-subject" />
                        </div>
                    </div>

                    <div className="row mb-4">
                        <div className="d-flex gap-4">
                            <div className="form-check">
                                <input disabled={isAllDisabled} {...register('room')} className="form-check-input" type="radio" value="1" id="lesson-plan-room-aee" />
                                <label className="form-check-label" htmlFor="lesson-plan-room-aee">
                                    Sala AEE
                                </label>
                            </div>
                            <div className="form-check">
                                <input disabled={isAllDisabled} {...register('room')} className="form-check-input" type="radio" value="2" id="lesson-plan-room-class" />
                                <label className="form-check-label" htmlFor="lesson-plan-room-class">
                                    Sala de Aula
                                </label>
                            </div>
                            <div className="form-check">
                                <input disabled={isAllDisabled} {...register('room')} className="form-check-input" type="radio" value="3" id="lesson-plan-room-tests" />
                                <label className="form-check-label" htmlFor="lesson-plan-room-tests">
                                    Testes
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex gap-1 flex-column mb-4">
                        <div className="form-check">
                            <input {...register('observations')} disabled={isAllDisabled} className="form-check-input" type="checkbox" value="1" id="lesson-plan-occurrence-1" />
                            <label className="form-check-label" htmlFor="lesson-plan-occurrence-1">
                                Teve Dificuldades e pediu ajuda.
                            </label>
                        </div>
                        <div className="form-check">
                            <input disabled={isAllDisabled} {...register('observations')} className="form-check-input" type="checkbox" value="2" id="lesson-plan-occurrence-2" />
                            <label className="form-check-label" htmlFor="lesson-plan-occurrence-2">
                                Sabia o que fazer e trabalhou independente.
                            </label>
                        </div>
                        <div className="form-check">
                            <input disabled={isAllDisabled} {...register('observations')} className="form-check-input" type="checkbox" value="3" id="lesson-plan-occurrence-3" />
                            <label className="form-check-label" htmlFor="lesson-plan-occurrence-3">
                                Demonstrou sonolência e cansaço.
                            </label>
                        </div>
                        <div className="form-check">
                            <input disabled={isAllDisabled} {...register('observations')} className="form-check-input" type="checkbox" value="4" id="lesson-plan-occurrence-4" />
                            <label className="form-check-label" htmlFor="lesson-plan-occurrence-4">
                                Trabalhou focado e concluiu a tarefa.
                            </label>
                        </div>
                        <div className="form-check">
                            <input disabled={isAllDisabled} {...register('observations')} className="form-check-input" type="checkbox" value="5" id="lesson-plan-occurrence-5" />
                            <label className="form-check-label" htmlFor="lesson-plan-occurrence-5">
                                Estava inquieto e barulhento.
                            </label>
                        </div>
                        <div className="lesson-plan-custom d-flex">
                            <div className="form-check">
                                <input disabled={isAllDisabled} {...register('observations')} className="form-check-input" type="checkbox" value="6" id="lesson-plan-occurrence-custom" />
                                <label className="form-check-label" htmlFor="lesson-plan-occurrence-custom">
                                </label>
                            </div>
                            <div>
                                <input onClick={handleCheckCustomObs} disabled={isAllDisabled} {...register('customObservation')} type="text" className="form-control form-control-sm" id="lesson-plan-subject" aria-describedby="lesson-plan-subject" />
                            </div>
                        </div>
                    </div>

                    <div className="row mb-4">
                        <div className="">
                            <label htmlFor="lesson-plan-comments" className="form-label">Comentários</label>
                            <textarea {...register('comments')} disabled={isAllDisabled} className="form-control" id="lesson-plan-comments" rows={3}></textarea>
                        </div>
                    </div>

                    {
                        props.prevLessonPlanData ? (
                            <></>
                        ) : (
                            <div className="d-flex gap-2">
                                <button className="btn btn-secondary" onClick={() => props.backToList()}>Cancel</button>
                                <button type="submit" className="btn btn-primary">Save</button>
                            </div>
                        )
                    }

                </form>
            </div>
        </div>
    )
}

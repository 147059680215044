import React, { ReactNode, useEffect, useState } from 'react';

import { IStudentBasicInfo, IStudentFullInfo } from '../../interfaces/IStudent';
import { IGradeLevel } from '../../interfaces/IGradeLevel';
import { addStudentToCookie, getCookie } from '../../utils/cookiesMngt';


export interface IResearch {
    name: string,
    teacher: string,
    grade: IGradeLevel,
}

interface IStudentPageContext {
    studentSelected: IStudentBasicInfo | undefined,
    setStudentSelected: (student: IStudentBasicInfo | undefined) => void,

    setResearchValues: (value: IResearch) => void,
    researchValues: IResearch,

    latestStudents: IStudentBasicInfo[],
}

const StudentPageContext = React.createContext<IStudentPageContext | undefined>(
    void 0
);

interface IProviderProps {
    children: ReactNode
}

export const StudentPageContextProvider = (props: IProviderProps) => {
    const { children } = props;
    const [studentSelected, setStudentSelected] = useState<IStudentBasicInfo | undefined>(undefined);
    const [latestStudents, setLatestStudents] = useState<IStudentBasicInfo[]>([]);
    const [researchValues, setResearchValues] = useState<IResearch>({
        grade: {
            docId: "",
            level: "",
        }, name: "", teacher: ""
    });

    useEffect(() => {
        loadLastSearchedStudents();
        if (studentSelected) {
            addStudentToCookie(studentSelected);
            loadLastSearchedStudents();
        }
    }, [studentSelected]);

    function loadLastSearchedStudents() {
        const cookies = getCookie('students');
        if (cookies.length > 0) {
            const studentsArray = JSON.parse(getCookie('students')) || [];
            setLatestStudents(studentsArray);
        }
    }

    const value = {
        studentSelected, setStudentSelected,
        researchValues, setResearchValues,
        latestStudents
    };

    return (
        <StudentPageContext.Provider value={value}>
            {children}
        </StudentPageContext.Provider>
    )

}

export function useStudentPageContext() {
    const context = React.useContext(StudentPageContext);

    if (typeof context === 'undefined') {
        throw new Error('useAuthContext must be used within an AuthContext');
    }
    return context;
}
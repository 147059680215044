import { collection, getDocs, query } from 'firebase/firestore';
import { db } from '../config/firestore'
import { ITeacher } from '../interfaces/ITeacher';

export async function getTeachers(): Promise<ITeacher[]> {
    const teachersCollectionRef = collection(db, "teachers");

    const queryResults = await getDocs(query(teachersCollectionRef));

    return queryResults.docs.map((doc) => {
        return ({
            docId: doc.id,
            name: doc.data().name,
            grades: doc.data().grades,
        })
    });
}


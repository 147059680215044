import { IGradeLevel } from '../interfaces/IGradeLevel';
import { IStudentBasicInfo, IStudentFullInfo } from '../interfaces/IStudent';
import { getTokenFromLocalStorage } from '../utils/localStorage';
import { throwApiException } from '../utils/throwApiException';


async function addStudent(student: IStudentBasicInfo): Promise<IStudentBasicInfo> {
    const response = await fetch(`${process.env.REACT_APP_API_CURRENT_URL}/students/add`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${getTokenFromLocalStorage().specialEdToken}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "student": student
        })
    });

    if (!response.ok) {
        await throwApiException(response);
    }

    return (await response.json()).data as IStudentBasicInfo;
}


async function updateStudentByDocId({ docId, student }: { docId: string, student: IStudentFullInfo }) {
    const response = await fetch(`${process.env.REACT_APP_API_CURRENT_URL}/students/update/${docId}`, {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${getTokenFromLocalStorage().specialEdToken}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "student": student
        })
    });

    if (!response.ok) {
        await throwApiException(response);
    }

}


async function getStudentByDocId({ docId }: { docId: string }): Promise<IStudentFullInfo> {
    const response = await fetch(`${process.env.REACT_APP_API_CURRENT_URL}/students/searchForOne/${docId}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${getTokenFromLocalStorage().specialEdToken}`,
            'Content-Type': 'application/json'
        },
    });

    if (!response.ok) {
        await throwApiException(response);
    }

    return (await response.json()).data;
}

async function getStudentsPerPage({ name, grade, teacher, startAfterDocId = '', }: { name: string, grade: IGradeLevel, teacher: string, startAfterDocId: string }): Promise<IStudentBasicInfo> {

    const baseURL = `${process.env.REACT_APP_API_CURRENT_URL}/students/searchForMany?`;
    console.log('grade: ', grade);
    const response = await fetch(`${baseURL}name=${name}&gradeLevelDocId=${grade.docId}&teacher=${teacher}&startAfterDocId=${startAfterDocId}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${getTokenFromLocalStorage().specialEdToken}`,
            'Content-Type': 'application/json'
        }
    });

    if (!response.ok) {
        await throwApiException(response);
    }

    return (await response.json()).data;
}

export { getStudentsPerPage, updateStudentByDocId, getStudentByDocId, addStudent }
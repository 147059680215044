import { IFAndPQuarter } from "../interfaces/IFAndPQuarter";

interface IOrderStudentFAndPQuarters {
    fAndP: {
        quarterOne: IFAndPQuarter,
        quarterTwo: IFAndPQuarter,
        quarterThree: IFAndPQuarter,
        quarterFour: IFAndPQuarter,
    }
}

export function orderStudentFAndPQuartes(props: IOrderStudentFAndPQuarters) {
    const quartersToPresent = new Array<IFAndPQuarter>(4);

    Object.keys(props.fAndP).forEach((quarterId) => {

        switch (quarterId) {
            case 'quarterOne':
                quartersToPresent[0] = {
                    ...props.fAndP['quarterOne'],
                    presentation: 'Q1',
                    id: quarterId,
                }
                break;
            case 'quarterTwo':
                quartersToPresent[1] = {
                    ...props.fAndP['quarterTwo'],
                    presentation: 'Q2',
                    id: quarterId,
                }
                break;
            case 'quarterThree':
                quartersToPresent[2] = {
                    ...props.fAndP['quarterThree'],
                    presentation: 'Q3',
                    id: quarterId,
                }
                break;
            case 'quarterFour':
                quartersToPresent[3] = {
                    ...props.fAndP['quarterFour'],
                    presentation: 'Q4',
                    id: quarterId,
                }
                break;
        }
    })

    return quartersToPresent;
}

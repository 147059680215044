import { getTokenFromLocalStorage } from '../utils/localStorage';
import { throwApiException } from '../utils/throwApiException';
import { IFolder } from '../interfaces/IFolder';

async function getFolder({ studentDocId, folderId = "0" }: { studentDocId: string, folderId: string }): Promise<IFolder> {
    const response = await fetch(`${process.env.REACT_APP_API_CURRENT_URL}/folders/${studentDocId}/${folderId}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${getTokenFromLocalStorage().specialEdToken}`,
            'Content-Type': 'application/json'
        },
    });

    if (!response.ok) {
        await throwApiException(response);
    }

    return (await response.json()).data;
}

async function addFolder({ studentDocId, folderParentId, folderName, }: { studentDocId: string, folderParentId: string, folderName: string }) {

    const response = await fetch(`${process.env.REACT_APP_API_CURRENT_URL}/folders/add/${studentDocId}/${folderParentId}`, {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${getTokenFromLocalStorage().specialEdToken}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            folderName: folderName
        })
    });

    if (!response.ok) {
        await throwApiException(response);
    }
}

export { getFolder, addFolder }
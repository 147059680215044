import React, { ReactNode, useState, useEffect } from 'react';
import { IUserLogged } from '../interfaces/IUserLogged';

interface IAuthContext {
    userLogged: IUserLogged | null,
    setUserLogged: (userLogged: IUserLogged | null) => void,

    tokenClient: any,
    setTokenClient: (token: any) => void;

    userHasPermission: (permission: string) => boolean,
}

const AuthContext = React.createContext<IAuthContext | undefined>(void 0);

interface IProviderProps {
    children: ReactNode,
}


export const AuthProvider = (props: IProviderProps) => {

    const { children } = props;

    const [userLogged, setUserLogged] = useState<IUserLogged | null>(null);

    const [tokenClient, setTokenClient] = useState<any>();


    function userHasPermission(permission: string): boolean {
        if (userLogged
            && userLogged.user.permissions
            && userLogged.user.permissions.includes(permission))
            return true
        else
            return false
    }

    const value = {
        userLogged, setUserLogged,
        tokenClient, setTokenClient,
        userHasPermission
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}

export function useAuthContext() {
    const context = React.useContext(AuthContext);

    if (typeof context === 'undefined') {
        throw new Error('useAuthContext must be used within an AuthContext');
    }
    return context;
}